import { createApp } from "vue/dist/vue.esm-bundler"
import Navbar from '@/components/Navbar/Navbar.vue'
import Specialists from '@/components/Specialist/Specialists.vue'
import PopularSpecialists from '@/components/Home/PopularSpecialists.vue'
import Services from '@/components/Service/Services.vue'
import Categories from '@/components/Category/Categories.vue'
import Rate from '@/components/Rate.vue'
import HomeHero from '@/components/Home/HomeHero.vue'
import Category from '@/components/Category/Category.vue'
import Specialist from '@/components/Specialist/Single/Specialist.vue'
import Cart from '@/components/Cart/Cart.vue'
import Service from '@/components/Service/Service.vue'
import UserProfile from '@/components/Auth/User/UserProfile.vue'
import SpecProfile from '@/components/Auth/Spec/SpecProfile.vue'
import UserOrders from '@/components/Auth/User/Order/UserOrders.vue'
import Alert from '@/components/Alert.vue'
import LocalizationModal from '@/components/LocalizationModal.vue'
import ShowPasswordButton from '@/components/Buttons/ShowPasswordButton.vue'
import Tip from '@/components/Tip.vue'
import Reviews from '@/components/Home/Reviews.vue'
import ContactUsToast from '@/modules/ContactUsToast'
import RequiredFieldsMessage from '@/components/Form/RequiredFieldsMessage.vue'
import LazyLoading from '@/components/LazyLoading.vue'
import SpecOrders from '@/components/Auth/Spec/Order/SpecOrders.vue'
import YouTubeVideo from '@/components/YouTubeVideo.vue'

const app = createApp({
    components: {
        HomeHero,
        Navbar,
        Specialists,
        PopularSpecialists,
        Services,
        Service,
        Category,
        Rate,
        Specialist,
        Cart,
        UserProfile,
        SpecProfile,
        UserOrders,
        Alert,
        LocalizationModal,
        Categories,
        ShowPasswordButton,
        Tip,
        Reviews,
        RequiredFieldsMessage,
        LazyLoading,
        SpecOrders,
        YouTubeVideo,
    },
    mounted() {
        new ContactUsToast(120).displayAfterSeconds()

        if (window.location.hostname === 'localhost') {
            return
        }

        let config = {}

        if (UserEmail !== '') {
            config = { userID: UserEmail }
        }
    }
})

app.config.globalProperties.$trans = Lang
app.config.globalProperties.$locale = Locale
app.config.globalProperties.$languagesLinks = LanguagesLinks

app.mount("#app")
