<script setup lang="ts">
import type FilterValue from '@/interfaces/Filter/FilterValue'
import type Filter from '@/interfaces/Filter/Filter'
import { computed, ref } from 'vue'
import useScroll from '@/composables/useScroll'
import LanguagesFilter from './LanguagesFilter.vue'
import CategoriesFilter from './CategoriesFilter.vue'
import ServicesFilter from './ServicesFilter.vue'
import QueryFilter from './QueryFilter.vue'
import SortField from '@/components/Sort/SortField.vue'
import useFilters from '@/composables/useFilters'

interface Props {
    title: string
    count: number
    filters: string[]
}

interface Emits {
    (e: 'filtersChanged', payload: FilterValue, filterName: string): void
}

const { title, count, filters } = defineProps<Props>()
const emit = defineEmits<Emits>()
const { isScrolledDown } = useScroll()
const { updateFiltersInUrl, updateFilters } = useFilters()
const showFilters = ref<boolean>(false)
const showFiltersTitle = computed(() => {
    return showFilters.value
        ? Lang.get('common.hide_filters')
        : Lang.get('common.show_filters')
})

function updateFilter(payload: number[] | string | number, filterName: keyof Filter): void {
    updateFilters(payload, filterName)
    updateFiltersInUrl()
    emit('filtersChanged', payload, filterName)
}

const updatedLanguages = (payload: number[]): void => updateFilter(payload, 'languages')
const updatedCategories = (payload: number[]): void => updateFilter(payload, 'categories')
const updatedServices = (payload: number[]): void => updateFilter(payload, 'services')
const updatedQuery = (payload: string): void => updateFilter(payload, 'query')
const updatedSort = (payload: number): void => updateFilter(payload, 'sort')
</script>

<template>
    <div
        class="fixed left-0 right-0 bg-page py-3 z-20 transition-all duration-500 border-b border-border"
        :class="{
            'top-[var(--page-top-sm-mobile)]': isScrolledDown,
            'top-[var(--page-top-sm-mobile)] lg:top-[var(--page-top-sm-desktop)]': !isScrolledDown,
        }"
    >
        <div class="container">
            <div class="block lg:hidden text-center">
                <button
                    type="button"
                    @click="showFilters = !showFilters"
                    class="text-sm font-bold text-font-second uppercase"
                >
                    <i class="lni lni-funnel mr-2 text-lg text-main font-bold"></i>

                    <span>{{ showFiltersTitle }}</span>

                    <i
                        class="lni ml-2 font-bold"
                        :class="showFilters ? 'lni-chevron-right' : 'lni-chevron-down'"
                        aria-hidden="true"
                    ></i>
                </button>
            </div>

            <div
                class="hidden lg:flex flex-col lg:flex-row gap-3 bg-page items-center justify-center md:justify-start gap-4"
                :class="{ '!flex left-0 right-0 fixed py-5 shadow-md': showFilters }"
            >
                <span class="hidden lg:inline-block text-font-second">
                    {{ $trans.get('common.filter') }}:
                </span>

                <languages-filter v-if="filters.includes('languages')" @changedItems="updatedLanguages" />
                <categories-filter v-if="filters.includes('categories')" @changedItems="updatedCategories" />
                <services-filter v-if="filters.includes('services')" @changedItems="updatedServices" />
                <query-filter v-if="filters.includes('query')" @changedItems="updatedQuery" />
            </div>
        </div>
    </div>

    <div class="container flex justify-center lg:justify-between items-center py-4 gap-4">
        <div class="hidden lg:block">
            <div v-if="count" class="flex items-center gap-x-2">
                <span class="text-font-second">{{ title }}:</span>

                <b class="font-bold text-xl text-main">
                    {{ count }}
                </b>
            </div>
        </div>

        <sort-field v-if="filters.includes('sort')" @updatedSort="updatedSort" />
    </div>
</template>
