<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import type User from '@/interfaces/User'
import MobileNavbar from './MobileNavbar.vue'
import Hamburger from './Hamburger.vue'
import NavMenu from './Menu/NavMenu.vue'
import Search from '@/components/Search/Search.vue'
import RightNavbar from './RightNavbar.vue'
import AppearTransition from '@/components/Transition/AppearTransition.vue'
import useScroll from '@/composables/useScroll'
import linkTo from '@/modules/linkTo'

const { isScrolledDown } = useScroll()

type Props = {
    client: User | null
    spec: Specialist | null
}

const { client, spec } = defineProps<Props>()
</script>

<template>
    <mobile-navbar />

    <appear-transition>
        <nav
            class="fixed z-30 top-0 left-0 right-0 h-[var(--page-top-sm-mobile)] bg-page transition-all duration-500 border-b border-border"
            :class="{
                'lg:h-[var(--page-top-sm-mobile)]': isScrolledDown,
                'lg:h-[var(--page-top-sm-desktop)]': !isScrolledDown,
            }"
        >
            <div class="container h-full px-0">
                <div class="flex justify-between h-full">
                    <!-- Left section -->
                    <div class="flex items-center w-full">
                        <hamburger />

                        <!-- Logo -->
                        <div
                            class="flex justify-center items-center h-full w-full mx-2 lg:mr-5 "
                            tabindex="-1"
                        >
                            <a
                                :href="linkTo('/')"
                                class="hover:opacity-60 transition-opacity"
                                aria-label="To main page"
                            >
                                <img
                                    src="/storage/logo.svg"
                                    alt="Blitz"
                                    class="h-8 max-w-none"
                                    aria-hidden="true"
                                    width="75"
                                    height="32"
                                />
                            </a>
                        </div>

                        <nav-menu />
                    </div>

                    <!-- Center section -->
                    <div class="relative w-full border-r hidden lg:flex items-center">
                        <search />
                    </div>

                    <div class="flex justify-end">
                        <right-navbar :client="client" :spec="spec" />
                    </div>
                </div>
            </div>
        </nav>
    </appear-transition>
</template>
