<script setup lang="ts">
import type ServiceType from '@/interfaces/Service'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import Spinner from '@/components/Spinner.vue'
import ServiceCard from './ServiceCard.vue'
import Filters from '@/components/Filter/Filters.vue'
import NotFound from '@/components/NotFound.vue'

const services = ref<ServiceType[]>([])
const loading = ref(true)

onMounted(fetchServices)

function filtersChanged(): void {
    fetchServices()
}

function fetchServices(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/services' + window.location.search,
    }

    makeRequest<ServiceType[]>(config)
        .then(data => services.value = data)
        .finally(() => loading.value = false)
}
</script>

<template>
    <div class="pb-7 min-h-[500px]">
        <filters
            :title="$trans.get('common.found_services')"
            :count="services.length"
            :filters="['categories', 'sort', 'query']"
            @filtersChanged="filtersChanged"
        />

        <div class="container">
            <div v-if="loading" class="text-center pt-5">
                <spinner class="mx-auto" />
            </div>

            <div v-else-if="!loading && services.length === 0" class="text-center pt-5">
                <not-found class="mx-auto">
                    {{ $trans.get('common.services_not_found') }}
                </not-found>
            </div>

            <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                <service-card
                    v-for="service in services"
                    :key="service.id"
                    :service="service"
                    :price="service.price"
                />
            </div>
        </div>
    </div>
</template>
