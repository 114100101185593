<script setup lang="ts">
interface Props {
    href?: string
    classes?: string
}

const { href, classes = '' } = defineProps<Props>()
let addClasses = 'transition-colors leading-5 border-r hover:bg-page-hover h-full flex items-center px-5 text-main-dark uppercase text-main-dark text-xs font-bold'

if (classes) {
    addClasses = `${addClasses} ${classes}`
}
</script>

<template>
    <a v-if="href" :href="href" :class="addClasses" aria-label="Navbar link">
        <slot />
    </a>

    <button v-else :class="addClasses" aria-label="Navbar link" type="button">
        <slot />
    </button>
</template>
