<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type Service from '@/interfaces/Service'
import useScroll from '@/composables/useScroll'
import DropdownLink from './DropdownLink.vue'
import DropdownShowMore from "./DropdownShowMore.vue";

interface Props {
    categories?: Category[]
    services?: Service[]
    parent?: Category
}

const { categories, services, parent } = defineProps<Props>()
const { isScrolledDown } = useScroll()
const maxServices = 4

if (services?.length) {
    if (services.length > maxServices) {
        services.splice(maxServices);
        services.short_list = true;
    }
}
</script>

<template>
    <aside
        class="flex absolute left-0 right-0 lg:right-auto lg:left-auto bg-page shadow-md border-t z-20 lg:-ml-5 transition-all"
        :class="{
            'top-[var(--page-top-sm-mobile)]': isScrolledDown,
            'top-[var(--page-top-sm-desktop)]': !isScrolledDown,
        }"
    >
        <div class="flex flex-col">
            <dropdown-link
                v-if="categories"
                v-for="category in categories"
                :key="category.id"
                :category="category"
            />
            <dropdown-link
                v-if="services"
                v-for="service in services"
                :key="service.id"
                :service="service"
            />
            <dropdown-show-more
                v-if="services && services.short_list === true"
                :parent="parent"
            />
        </div>
    </aside>
</template>
