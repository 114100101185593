<script setup lang="ts">
import type Auth from '@/interfaces/Auth'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import showToast from '@/modules/showToast'
import FormInput from '@/components/Form/FormInput.vue'
import ProfileTitle from '@/components/Auth/Profile/ProfileTitle.vue'
import linkTo from '@/modules/linkTo'
import RequiredFieldsMessage from '@/components/Form/RequiredFieldsMessage.vue'

type Props = {
    auth: Auth
    savePasswordUrl: string
}

const { auth, savePasswordUrl } = defineProps<Props>()
const loading = ref(false)

const form = ref({
    old_password: '',
    password: '',
    password_confirmation: '',
    slug: auth.slug,
})

function saveForm(): void {
    if (loading.value) {
        return
    }

    loading.value = true

    const conf: MakeRequestConfig = {
        url: savePasswordUrl,
        method: 'put',
        data: form.value,
    }

    makeRequest<string>(conf)
        .then(data => {
            showToast({ text: data })
            form.value.old_password = ''
            form.value.password = ''
            form.value.password_confirmation = ''
        })
        .finally(() => loading.value = false)
}
</script>

<template>
    <profile-title
        :title="$trans.get('common.password')"
        :intro="$trans.get('common.password_description')"
    />

    <form @submit.prevent="saveForm" class="w-full">
        <div class="bg-page p-7 rounded-lg space-y-5 shadow-md w-full">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 max-w-2xl">
                <form-input
                    :label="$trans.get('common.current_password')"
                    name="old_password"
                    @changed="(value: string) => form.old_password = value"
                    type="password"
                    :value="form.old_password"
                    :is-required="true"
                />

                <div class="flex flex-col justify-center">
                    <label class="block text-sm text-font-second">
                        {{ $trans.get('common.forgot_password') }}
                    </label>

                    <a :href="linkTo('password/reset')" class="text-main">
                        {{ $trans.get('common.reset_password') }}
                    </a>
                </div>

                <form-input
                    :label="$trans.get('common.new_password')"
                    name="password"
                    @changed="(value: string) => form.password = value"
                    type="password"
                    :value="form.password"
                    :is-required="true"
                />

                <form-input
                    :label="$trans.get('common.confirm_password')"
                    name="password_confirmation"
                    @changed="(value: string) => form.password_confirmation = value"
                    type="password"
                    :value="form.password_confirmation"
                    :is-required="true"
                />
            </div>


            <div class="mt-7">
                <required-fields-message />
            </div>
        </div>

        <div class="text-right mt-6">
            <button
                type="submit"
                class="bg-main min-w-[300px] text-white px-8 py-3 rounded-full shadow-md transition-colors hover:bg-main-hover min-w-[180px]"
            >
                <i v-if="loading" class="lni lni-spinner animate-spin" aria-hidden="true"></i>

                <span v-else>
                    <i class="lni lni-save mr-2" aria-hidden="true"></i>
                    {{ $trans.get('common.save_new_password') }}
                </span>
            </button>
        </div>
    </form>
</template>
