<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type Specialist from '@/interfaces/Specialist'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import VueHorizontal from 'vue-horizontal'
import VerticalSpecialistCard from '@/components/Specialist/VerticalSpecialistCard.vue'
import linkTo from '@/modules/linkTo'
import NotFound from '@/components/NotFound.vue'

interface Props {
    category: Category
}

const { category } = defineProps<Props>()
const specialists = ref<Specialist[]>([])

onMounted(fetchSpecialists)

function fetchSpecialists(): void {
    const config: MakeRequestConfig = {
        url: `/api/categories/offered-specialists/${category.id}`,
    }

    makeRequest<Specialist[]>(config)
        .then(data => specialists.value = data)
}
</script>

<template>
    <vue-horizontal v-if="specialists.length > 0">
        <div
            v-for="specialist in specialists"
            :key="specialist.id"
            class="w-full max-w-[295px] mr-5"
        >
            <vertical-specialist-card
                :specialist="specialist"
                :price="specialist.price"
                class="border shadow-none"
                :link="linkTo(`specialists/${specialist.slug}`)"
            />
        </div>
    </vue-horizontal>

    <not-found v-else>
        {{ $trans.get('common.no_specialists_for_this_category') }}
    </not-found>
</template>
