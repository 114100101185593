<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import getNavbarLinks from '@/modules/navbar/getNavbarLinks'
import { onMounted, ref } from 'vue'
import useDropdown from '@/composables/navbar/useDropdown'
import makeRequest from '@/modules/makeRequest'
import NavIcon from '../NavIcon.vue'
import MobileDropdown from '@/components/Navbar/Dropdown/Mobile/MobileDropdown.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import MobileNavLink from './MobileNavLink.vue'
import MobileLanguageSwitcher from '@/components/Navbar/MobileLanguageSwitcher.vue'
import isActiveMenu from '@/modules/isActiveMenu'

const categories = ref<Category[]>([])
const { dropdownIsVisible } = useDropdown()

onMounted(() => {
    fetchDropdownItems()
})

function fetchDropdownItems(): void {
    const config: MakeRequestConfig = {
        url: '/api/nested-menu',
    }

    makeRequest<Category[]>(config)
        .then(data => categories.value = data)
}
</script>

<template>
    <div class="flex flex-col text-xs font-bold text-gray-600 h-screen overflow-y-scroll">
        <div
            v-for="link in getNavbarLinks()"
            :key="link.title"
            class="w-full border-b last:border-none"
        >
            <mobile-nav-link
                :href="link.href && !link.isDropdown ? link.href : null"
                :aria-expanded="!!link.isDropdown"
                aria-haspopup="menu"
                @click="link.isDropdown ? (dropdownIsVisible = !dropdownIsVisible) : null"
                :classes="isActiveMenu(link.href) ? 'bg-page-hover' : ''"
            >
                <span class="uppercase">{{ link.title }}</span>

                <nav-icon
                    v-if="link.isDropdown"
                    iconName="lni-chevron-down"
                    class="ml-3 text-xs font-black"
                />
            </mobile-nav-link>

            <slide-bottom-transition>
                <mobile-dropdown
                    v-if="dropdownIsVisible && link.isDropdown"
                    :categories="categories"
                    @pressBackButton="dropdownIsVisible = false"
                />
            </slide-bottom-transition>
        </div>

        <mobile-language-switcher />
    </div>
</template>
