import axios, { AxiosInstance } from 'axios'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import showToast from '@/modules/showToast'

export default async <T>(config: MakeRequestConfig): Promise<T> => {
    return new Promise((resolve, reject) => {
        if (!config.url) {
            displayAlert(Lang.get('common.url_not_provided'), false, config)
            reject(new Error(Lang.get('common.url_not_provided')))
            return
        }

        if (Locale !== DefaultLocale && !config.url.includes(`/api/${Locale}`)) {
            config.url = config.url.replace('/api', `/api/${Locale}`)
        }

        axiosRequest()
            .request<T>(config)
            .then(resp => resolve(resp.data))
            .catch(err => {
                if (!err) {
                    reject(new Error(Lang.get('common.unknown_error')))
                    return
                }

                console.error(err)

                let result = Lang.get('common.server_error')
                let errMsg1 = err.response.data || null
                let errMsg2 = err.response.data.message || null

                if (typeof errMsg1 === 'string') {
                    result = errMsg1
                } else if (typeof errMsg2 === 'string') {
                    result = errMsg2
                }

                displayAlert(result, false, config)

                reject(new Error(result))
            })
    })
}

function displayAlert(text: string, success: boolean, config: MakeRequestConfig): void {
    const duration = config.toastDuration ? config.toastDuration : 7000
    showToast({ text, success, duration })
}

function axiosRequest(): AxiosInstance {
    const request = axios.create({
        withCredentials: true,
        headers: {
            'Accept': 'application/json',
        },
    })

    request.interceptors.response.use(resp => resp, err => {
        return Promise.reject(err)
    })

    return request
}
