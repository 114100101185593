<script setup lang="ts">
import type Filter from '@/interfaces/Filter/Filter'
import { ref } from 'vue'
import FilterButton from './Elements/FilterButton.vue'
import { Listbox } from '@headlessui/vue'
import Tip from '../Tip.vue'
import listenEvent from '@/modules/listenEvent'
import appConfig from '@/appConfig'

interface Emits {
    (e: 'changedItems', payload: []): void
}

const emit = defineEmits<Emits>()
const query = ref<string>('')

function clearFilter(): void {
    query.value = ''
    emit('changedItems', [])
}

listenEvent<Filter>(appConfig.events.activeFilters, filter => query.value = filter.query)
</script>

<template>
    <div v-if="query" class="inline-block relative">
        <listbox>
            <tip :content="$trans.get('common.search_request') + ': ' + query">
                <filter-button
                    :hideChevron="true"
                    :title="$trans.get('common.request') + ': ' + query"
                    :disabled="false"
                    @clearFilter="clearFilter"
                />
            </tip>
        </listbox>
    </div>
</template>
