import toast from 'toastify-js'

interface ShowToastParams {
    text: string
    success?: boolean
    url?: string
    duration?: number
}

export default (params: ShowToastParams) => {
    if (typeof params.success === 'undefined')
        params.success = true

    const config: toast.Options = {
        text: `
        <div class="flex items-center gap-6">
            <i class="lni ${params.success ? 'lni-checkmark text-green-600' : 'lni-sad text-red-600'} text-xl font-black"></i>
            <span>${params.text}</span>
        </div>
        `,
        destination: params.url || 'javascript:',
        escapeMarkup: false,
        gravity: 'bottom',
        position: 'right',
        close: true,
        duration: params.duration || 5000,
        className: 'text-gray-600 flex fixed p-6 justify-between z-50 gap-2 rounded-md right-0 md:right-10 max-w-lg rounded-none sm:rounded-lg shadow-[0_0_20px_rgba(0,0,0,.8)]',
        style: {
            background: 'white',
        },
    }

    toast(config).showToast()
}
