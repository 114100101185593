import type {CachedCartItem} from '@/interfaces/CartItem'
import appConfig from '@/appConfig'
import CouponCache from '@/modules/Cache/CouponCache'
import OrderType from "@/interfaces/Order/OrderType";

export default class {
    public static add(specialistId: number, serviceId: number): void {
        let cartItems = this.get()

        cartItems.push({
            specialistId: specialistId,
            service: serviceId,
            type: null,
            appointmentDate: null,
            duration: null,
            coupon: CouponCache.getCouponByServiceId(specialistId, serviceId),
        })

        this.save(cartItems)
    }

    public static get(): CachedCartItem[] {
        const order = localStorage.getItem(appConfig.storageKeys.order)
        return order ? JSON.parse(order) : []
    }

    public static getCartItem(specialistId: number, serviceId: number): CachedCartItem | null {
        const cartItems = this.get()
        const cartItem = cartItems.find(item => {
            return item.specialistId === specialistId && item.service === serviceId;
        });

        if (!cartItem) {
            return null
        }

        return cartItem
    }

    public static removeAll(): void {
        localStorage.removeItem(appConfig.storageKeys.order)
        localStorage.removeItem(appConfig.storageKeys.orderComment)
        localStorage.removeItem(appConfig.storageKeys.orderCoupons)
    }

    public static removeCartItem(specialistId: number, serviceId: number): void {
        let cartItems = this.get()

        if (cartItems.length === 0) {
            return
        }

        cartItems = cartItems.filter(item => {
            return item.specialistId !== specialistId || item.service !== serviceId;
        });

        this.save(cartItems)
    }

    public static containsService(specialistId: number, serviceId: number): boolean {
        const cartItems = this.get();

        if (cartItems.length === 0) {
            return false;
        }

        return cartItems.some(item => {
            return item.specialistId === specialistId && item.service === serviceId;
        });
    }

    public static updateAppointment(specialistId: number, serviceId: number, appointmentDate: Date): void {
        let cartItems = this.get()

        if (!cartItems) {
            return
        }

        const cartItem = cartItems.map(item => {
            if (item.specialistId !== specialistId || item.service !== serviceId ) {
                return item
            }

            item.appointmentDate = appointmentDate

            return item
        })

        this.save(cartItems)
    }

    public static updateDuration(specialistId: number, serviceId: number, duration: number): void {
        let cartItems = this.get()

        if (!cartItems) {
            return
        }

        const cartItem = cartItems.map(item => {
            if (item.specialistId !== specialistId || item.service !== serviceId ) {
                return item
            }

            item.duration = duration

            return item
        })

        this.save(cartItems)
    }

    public static updateType(specialistId: number, serviceId: number, type: OrderType | null): void {
        let cartItems = this.get()

        if (!cartItems) {
            return
        }

        const cartItem = cartItems.map(item => {
            if (item.specialistId !== specialistId || item.service !== serviceId ) {
                return item
            }

            item.type = type

            return item
        })

        this.save(cartItems)
    }

    public static updateCoupon(specialistId: number, serviceId: number, coupon: string | null): void {
        let cartItems = this.get()

        if (!cartItems) {
            return
        }

        const cartItem = cartItems.map(item => {
            if (item.specialistId !== specialistId || item.service !== serviceId ) {
                return item
            }

            item.coupon = coupon

            return item
        })

        this.save(cartItems)
    }

    public static countServices(): number {
        return this.get().reduce((prev, cartItem) => {
            return ++prev
        }, 0)
    }

    private static save(order: CachedCartItem[]): void {
        localStorage.setItem(appConfig.storageKeys.order, JSON.stringify(order))
    }

    private static removeDuplicates(services: number[], id: number): number[] {
        return services.filter(serv => serv !== id)
    }
}
