import CartItem, { DisabledDate } from '@/interfaces/CartItem'
import type { CartItemRaw } from '@/interfaces/Responses/FillOrderResponse'

function convertDisabledDates(cartItemRaw: CartItemRaw): DisabledDate[] {
    const result: DisabledDate[] = []

    for (const disabledDate of cartItemRaw.appointment.disabledDates) {
        if (disabledDate.start && disabledDate.end) {
            result.push({
                start: new Date(disabledDate.start),
                end: new Date(disabledDate.end),
            })
        }

        if (disabledDate.weekdays) {
            result.push({weekdays: disabledDate.weekdays})
        }
    }

    return result
}

export default (cartItemsRaw: CartItemRaw[]): CartItem[] => {
    const cartItems: CartItem[] = []

    for (const cartItemRaw of cartItemsRaw) {
        cartItems.push({
            specialistId: cartItemRaw.specialistId,
            specialistSlug: cartItemRaw.specialistSlug,
            imageUrl: cartItemRaw.imageUrl,
            title: cartItemRaw.title,
            service: cartItemRaw.service,
            coupon: cartItemRaw.coupon,
            type: cartItemRaw.type,
            appointment: {
                date: new Date(cartItemRaw.appointment.date),
                workingHours: [],
                disabledDates: convertDisabledDates(cartItemRaw),
            }
        })
    }
    return cartItems
}
