<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import type Service from '@/interfaces/Service'
import type CouponEvent from '@/interfaces/CouponEvent'
import type { PricePivot } from '@/interfaces/Service'
import { onMounted, ref } from 'vue'
import appConfig from '@/appConfig'
import OrderCache from '@/modules/Cache/OrderCache'
import dispatchEvent from '@/modules/dispatchEvent'
import showToast from '@/modules/showToast'
import ServiceTag from './ServiceTag.vue'
import listenEvent from '@/modules/listenEvent'
import CouponPopup from "@/components/CouponPopup.vue";

interface Props {
    service: Service
    specialist: Specialist
}

const { service, specialist } = defineProps<Props>()
const isSelected = ref<boolean>(false)
const couponEvent = ref<CouponEvent>({
    state: false,
    specialistId: null,
    serviceId: null,
})

onMounted(() => {
    isSelected.value = OrderCache.containsService(specialist.id, service.id)
})

listenEvent(appConfig.events.cartIsUpdated, () => {
    isSelected.value = OrderCache.containsService(specialist.id, service.id)
})

function toggleSelectedService(): void {
    isSelected.value = !isSelected.value

    if (isSelected.value) {
        if (service.coupon_only) {
            couponEvent.value = {
                state : true,
                specialistId : specialist.id,
                serviceId : service.id
            }

            dispatchEvent(appConfig.events.showCouponPopup, couponEvent.value)
        } else {
            OrderCache.add(specialist.id, service.id)
            showToast({ text: Lang.get('common.service_added') })
        }
    } else {
        OrderCache.removeCartItem(specialist.id, service.id)
        showToast({ text: Lang.get('common.service_removed'), success: false })
    }

    dispatchEvent(appConfig.events.refreshCart)
}
</script>

<template>
    <div
        class="pl-7 md:pl-16 flex flex-col gap-3 md:flex-row justify-between py-3 transition-colors hover:bg-gray-100 cursor-default rounded-sm"
        @click="toggleSelectedService"
    >
        <div>
            <i
                class="lni mr-3 font-black text-sm"
                :class="isSelected ? 'lni-checkmark text-green-700' : 'lni-stop text-gray-500'"
                aria-hidden="true"
            ></i>
            <span :class="{ 'text-main': isSelected }">{{ service.title }}</span>
        </div>

        <div class="flex gap-3 text-sm">
            <service-tag>~ {{ service.time }} {{ $trans.get('common.mins') }}</service-tag>
            <service-tag>
                {{ service.pivot ? (service.pivot as PricePivot).price : '-' }} €
            </service-tag>
        </div>

        <coupon-popup :service="service" :specialist="specialist"/>
    </div>
</template>
