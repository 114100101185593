<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import type Category from '@/interfaces/Category'
import ServiceTreeItem from './ServiceTreeItem.vue'
import { onMounted, ref } from 'vue'
import OrderCache from '@/modules/Cache/OrderCache'
import CouponPopup from "@/components/CouponPopup.vue";

interface Props {
    category: Category
    specialist: Specialist
    index: number
}

const { category, index, specialist } = defineProps<Props>()
const expand = ref<boolean>(index === 0)

onMounted(() => {
    expand.value = hasSelectedService()
})

function hasSelectedService(): boolean {
    if (index === 0 || !category.services) {
        return true
    }

    const selectedService = category.services.find(service => {
        return OrderCache.containsService(specialist.id, service.id)
    })

    return !!selectedService
}
</script>

<template>
    <div>
        <div
            class="flex justify-between items-center py-3 cursor-default px-3 transition-colors hover:bg-gray-100 rounded-sm"
            @click="expand = !expand"
        >
            <div class="flex items-center">
                <i
                    class="lni text-xs font-black mr-4"
                    :class="expand ? 'lni-chevron-down text-green-700' : 'lni-chevron-right text-gray-500'"
                    aria-hidden="true"
                ></i>

                <span class="font-bold leading-5" :class="{ 'text-main': expand }">
                    {{ category.title }}
                </span>
            </div>

            <span class="text-gray-500 lowercase text-sm hidden md:inline">
                {{ $trans.get('common.services') }}:
                <b v-if="category.services">{{ category.services.length }}</b>
                <b v-else>-</b>
            </span>
        </div>

        <div v-if="expand">
            <div
                v-if="category.services"
                v-for="service in category.services"
                :key="service.id"
            >
                <service-tree-item
                    :service="service"
                    :specialist="specialist"
                    class="border-b border-border"
                />
            </div>
        </div>
    </div>
</template>
