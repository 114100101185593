<script setup lang="ts">
import type Category from '@/interfaces/Category'
import { computed } from 'vue'
import getIconNameForCategory from '@/modules/navbar/getIconNameForCategory'

type Props = {
    category?: Category
}

const { category } = defineProps<Props>()

const linkIconName = computed<string | null>(() => {
    if (!category)
        return null

    return getIconNameForCategory(category.id)
})
</script>

<template>
    <i
        v-if="linkIconName"
        :class="`lni ${linkIconName}`"
        class="text-xl text-gray-500 font-black"
    ></i>
</template>
