import sweetAlert from 'sweetalert2/src/sweetalert2'
import type { SweetAlertOptions } from 'sweetalert2'

export default async (options: SweetAlertOptions): Promise<boolean> => {
    const response = await sweetAlert.fire({
        title: Lang.get('common.confirm_operation'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: Lang.get('common.cancel'),
        ...options,
    })

    return response.isConfirmed
}
