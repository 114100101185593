<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import getNavbarLinks from '@/modules/navbar/getNavbarLinks'
import { onMounted, ref } from 'vue'
import useDropdown from '@/composables/navbar/useDropdown'
import makeRequest from '@/modules/makeRequest'
import NavLinks from './NavLinks.vue'
import NavLink from './NavLink.vue'
import NavIcon from './NavIcon.vue'
import Dropdown from '@/components/Navbar/Dropdown/Dropdown.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import isActiveMenu from '@/modules/isActiveMenu'

const categories = ref<Category[]>([])
const { dropdownIsVisible, toggleDropdown } = useDropdown()

onMounted(() => {
    fetchDropdownItems()
})

function fetchDropdownItems(): void {
    const config: MakeRequestConfig = {
        url: '/api/nested-menu',
    }

    makeRequest<Category[]>(config)
        .then(data => categories.value = data)
}
</script>

<template>
    <div class="hidden lg:block h-full">
        <nav-links>
            <nav-link
                v-for="link in getNavbarLinks()"
                :key="link.title"
                :href="link.href"
                :aria-expanded="!!link.isDropdown"
                :aria-label="`Link to ${link.title}`"
                aria-haspopup="menu"
                @mouseenter="link.isDropdown ? toggleDropdown(true) : null"
                @mouseleave="link.isDropdown ? toggleDropdown(false) : null"
                :classes="isActiveMenu(link.href) ? 'bg-page-hover' : ''"
            >
                <span class="text-main-dark">{{ link.title }}</span>

                <nav-icon
                    v-if="link.isDropdown"
                    iconName="lni-chevron-down"
                    class="ml-3 text-xs font-black"
                />

                <slide-bottom-transition>
                    <dropdown
                        v-if="dropdownIsVisible && link.isDropdown"
                        :categories="categories"
                    />
                </slide-bottom-transition>
            </nav-link>
        </nav-links>
    </div>
</template>
