import type Filter from '../interfaces/Filter/Filter'
import type FilterValue from '../interfaces/Filter/FilterValue'
import { onMounted, ref } from 'vue'
import parseUrlParams from '../modules/parseUrlParams'
import dispatchEvent from '../modules/dispatchEvent'
import convertFiltersToUrlPath from '../modules/convertFiltersToUrlPath'
import appConfig from '../appConfig'

export default () => {
    const filters = ref<Filter>({
        languages: [],
        categories: [],
        services: [],
        sort: 1,
        query: '',
    })

    onMounted(() => {
        setFiltersBasedOnUrl(window.location.href)
    })

    function setFiltersBasedOnUrl(url: string): void {
        const urlPath = window.location.search
        const urlParams = parseUrlParams(urlPath)

        for (const urlParamName in urlParams) {
            const urlParamValue: string = urlParams[urlParamName]

            switch (urlParamName) {
                case 'languages':
                case 'categories':
                case 'services':
                    filters.value[urlParamName] = urlParamValue.split(',').map(id => Number(id))
                    break
                case 'sort':
                    filters.value.sort = Number(urlParamValue)
                    break
                case 'query':
                    filters.value.query = urlParamValue
            }
        }

        dispatchEvent(appConfig.events.activeFilters, filters.value)
    }

    function updateFilters(payload: FilterValue, filterName: keyof Filter): void {
        switch (filterName) {
            case 'languages':
            case 'categories':
            case 'services':
                filters.value[filterName] = payload as number[]
                break
            case 'query':
                filters.value.query = payload as string
                break
            case 'sort':
                filters.value.sort = payload as number
        }
    }

    function updateFiltersInUrl(): void {
        const newUrlPath = convertFiltersToUrlPath(filters.value)

        if (!newUrlPath) {
            return
        }

        window.history.pushState(null, '', newUrlPath)
    }

    return {
        updateFiltersInUrl,
        updateFilters,
    }
}
