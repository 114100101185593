<script setup lang="ts">
import type Language from '@/interfaces/Language'
import type Filter from '@/interfaces/Filter/Filter'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref, watch } from 'vue'
import { Listbox } from '@headlessui/vue'
import listenEvent from '@/modules/listenEvent'
import appConfig from '@/appConfig'
import makeRequest from '@/modules/makeRequest'
import FilterButton from './Elements/FilterButton.vue'
import FilterOption from './Elements/FilterOption.vue'
import FilterOptions from './Elements/FilterOptions.vue'
import FilterNumber from './Elements/FilterNumber.vue'

interface Emits {
    (e: 'changedItems', ids: number[]): void
}

const emit = defineEmits<Emits>()

const selectedIds = ref<number[]>([])
const selectedItems = ref<Language[]>([])
const items = ref<Language[]>([])
const loading = ref<boolean>(true)
const allowWatch = ref(false)

onMounted(fetchItems)

watch(selectedItems, () => {
    if (allowWatch.value) {
        let ids: number[] = []
        selectedItems.value.forEach(item => ids.push(item.id))
        emit('changedItems', ids)
    }
})

function fetchItems(): void {
    const config: MakeRequestConfig = {
        url: '/api/languages',
    }

    makeRequest<Language[]>(config)
        .then(data => setItems(data))
        .finally(() => loading.value = false)
}

function setItems(responseItems: Language[]): void {
    items.value = responseItems
    selectedItems.value = responseItems.filter(item => selectedIds.value.includes(item.id))
    setTimeout(() => allowWatch.value = true, 500)
}

function clearFilter(): void {
    selectedItems.value = []
    selectedIds.value = []
    emit('changedItems', [])
}

listenEvent<Filter>(appConfig.events.activeFilters, filter => {
    selectedIds.value = filter.languages
})
</script>

<template>
    <div class="inline-block relative">
        <listbox v-model="selectedItems" multiple>
            <filter-button
                :disabled="selectedItems.length === 0"
                :title="$trans.get('common.languages')"
                @clearFilter="clearFilter"
            >
                <filter-number v-if="selectedItems.length > 0">
                    {{ selectedItems.length }}
                </filter-number>
            </filter-button>

            <filter-options :loading="loading">
                <filter-option
                    v-for="item in items"
                    :key="item.id"
                    :value="item"
                >
                    <div class="flex items-center gap-x-2">
                        <img
                            v-if="item.icon"
                            :src="item.icon"
                            :alt="item.title"
                            width="20"
                            height="13"
                            class="w-5 rounded-md shadow-sm"
                        />

                        <span class="text-sm">{{ item.title }}</span>
                    </div>
                </filter-option>
            </filter-options>
        </listbox>
    </div>
</template>
