<script setup lang="ts">
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type Auth from '@/interfaces/Auth'
import { ref } from 'vue'
import appConfig from '@/appConfig'
import makeRequest from '@/modules/makeRequest'
import showToast from '@/modules/showToast'
import dispatchEvent from '@/modules/dispatchEvent'
import FormInput from '@/components/Form/FormInput.vue'
import ProfileTitle from '@/components/Auth/Profile/ProfileTitle.vue'
import RequiredFieldsMessage from '@/components/Form/RequiredFieldsMessage.vue'

type Props = {
    auth: Auth
    saveInfoUrl: string
}

const { auth, saveInfoUrl } = defineProps<Props>()
const loading = ref(false)

const form = ref({
    first: auth.first,
    last: auth.last,
    slug: auth.slug,
})

function saveForm(): void {
    if (loading.value) {
        return
    }

    loading.value = true

    const conf: MakeRequestConfig = {
        url: saveInfoUrl,
        method: 'put',
        data: form.value,
    }

    makeRequest(conf)
        .then(data => {
            showToast({ text: Lang.get('common.data_saved') })
            dispatchEvent(appConfig.events.userIsUpdated, form.value)
        })
        .finally(() => loading.value = false)
}
</script>

<template>
    <profile-title
        :title="$trans.get('common.personal_info')"
        :intro="$trans.get('common.personal_info_description')"
    />

    <form @submit.prevent="saveForm">
        <div class="gap-5 bg-page p-7 rounded-lg shadow-md">
            <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-6">
                <form-input
                    :label="$trans.get('common.your_first_name')"
                    name="first"
                    :value="form.first || undefined"
                    @changed="(value: string) => form.first = value"
                    :warning="['ru', 'uk'].includes($locale) ? $trans.get('common.latin') : undefined"
                    :is-required="true"
                    :placeholder="`${$trans.get('common.example')}: Anna`"
                />

                <form-input
                    :label="$trans.get('common.your_last_name')"
                    name="last"
                    :value="form.last || undefined"
                    @changed="(value: string) => form.last = value"
                    :warning="['ru', 'uk'].includes($locale) ? $trans.get('common.latin') : undefined"
                    :is-required="true"
                    :placeholder="`${$trans.get('common.example')}: Korotchaeva`"
                />

                <form-input
                    :label="$trans.get('common.email')"
                    name="email"
                    :value="auth.email"
                    :disabled="true"
                    type="password"
                    :is-required="true"
                />

                <form-input
                    :label="$trans.get('common.phone')"
                    name="phone"
                    :value="auth.phone || undefined"
                    :disabled="true"
                    type="password"
                    :is-required="true"
                />
            </div>

            <div class="mt-6">
                <required-fields-message />
            </div>
        </div>

        <div class="text-right mt-7">
            <button
                type="submit"
                class="bg-main text-white min-w-[220px] px-8 py-3 rounded-full shadow-md transition-colors hover:bg-main-hover"
            >
                <i v-if="loading" class="lni lni-spinner animate-spin" aria-hidden="true"></i>
                <span v-else>
                    <i class="lni lni-save mr-1" aria-hidden="true"></i>
                    {{ $trans.get('common.save') }}
                </span>
            </button>
        </div>
    </form>
</template>
