<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import Rate from '@/components/Rate.vue'
import ServiceTags from './ServiceTags.vue'
import Languages from '@/components/Languages.vue'
import linkTo from '@/modules/linkTo'
import NotAvailable from '@/components/Specialist/NotAvailable.vue'

interface Props {
    specialist: Specialist
}

const { specialist } = defineProps<Props>()
const link = linkTo(`specialists/${specialist.slug}`)
</script>

<template>
    <div class="bg-page p-5 rounded-lg shadow-md hover:shadow-lg duration-500">
        <div class="grid grid-cols-1 lg:grid-cols-[1fr_auto] gap-x-3 gap-y-5">
            <div class="flex flex-col">
                <div class="flex gap-x-4 items-center">
                    <a :href="link" class="w-[80px]">
                        <img
                            :src="specialist.image_sm + '?v=1'"
                            :alt="`${$trans.get('common.specialist')} ${specialist.name}`"
                            class="rounded-full shadow-md w-[61px]"
                            width="100"
                            height="100"
                        />
                    </a>
                    <div class="flex flex-col w-full gap-y-2">
                        <a :href="link" class="text-main-dark font-bold transition-colors hover:text-font">
                            {{ specialist.name }}
                        </a>

                        <rate :rate="specialist.rate" />
                        <languages :languages="specialist.languages" />
                    </div>
                </div>

                <div class="mt-3">
                    <service-tags
                        v-if="specialist.services && specialist.services.length > 0"
                        :services="specialist.services"
                    />

                    <not-available v-else />

                    <div class="mt-3">
                        {{ specialist.short_about }}
                    </div>
                </div>
            </div>

            <div class="flex flex-row lg:flex-col justify-between items-end ">
                <div class="flex flex-row-reverse lg:flex-col gap-4 lg:gap-2 items-center">
                    <b v-if="specialist.price === 0" class="text-right flex flex-col text-lg">
                        {{ $trans.get('common.free') }}
                    </b>

                    <b v-if="specialist.price !== 0" class="text-right flex flex-col text-lg">
                        {{ $trans.get('common.from') }}
                        {{ specialist.pretty_price }}
                    </b>

                    <div v-if="specialist.company">
                        <img
                            :src="specialist.company.image"
                            :alt="`${specialist.company.name} company logo`"
                            width="180"
                            height="180"
                            class="w-12 aspect-square"
                        />
                    </div>
                </div>

                <a
                    v-if="specialist.services && specialist.services.length !== 0"
                    :href="link"
                    class="border border-main-dark font-bold text-main-dark hover:border-decor hover:text-decor py-2 px-5 rounded-full transition-colors uppercase text-xs shadow-md"
                >
                    {{ $trans.get('common.choose') }}
                </a>
            </div>
        </div>
    </div>
</template>
