<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type Service from '@/interfaces/Service'
import { computed } from 'vue'
import NavIcon from '@/components/Navbar/Menu/NavIcon.vue'
import Dropdown from './Dropdown.vue'
import DropdownHorizontalTransition from '@/components/Transition/DropdownHorizontalTransition.vue'
import useDropdown from '@/composables/navbar/useDropdown'
import linkTo from '@/modules/linkTo'
import CategoryLinkIcon from '@/components/Navbar/CategoryLinkIcon.vue'
import useNavLinkTitle from '@/composables/navbar/useNavLinkTitle'

interface Props {
    category?: Category
    service?: Service
}

const { category, service } = defineProps<Props>()
const { dropdownIsVisible, toggleDropdown } = useDropdown()
const { linkTitle } = useNavLinkTitle(service, category)

const link = computed<string>(() => {
    if (service) {
        return linkTo(`services/${service.slug}`)
    }

    return category ? linkTo(`categories/${category.slug}`) : ''
})
</script>

<template>
    <a
        class="px-4 py-4 border-b w-full last:border-none min-w-[330px] transition-colors hover:bg-gray-100"
        :href="link"
        aria-haspopup="menu"
        @mouseenter="toggleDropdown(true)"
        @mouseleave="toggleDropdown(false)"
    >
        <span class="flex items-center justify-between gap-3">
            <div class="flex items-center gap-3">
                <category-link-icon :category="category" />
                <span class="text-main-dark">{{ linkTitle }}</span>
            </div>

            <nav-icon
                v-if="category"
                iconName="lni-chevron-right"
                class="ml-3 text-xs font-black"
            />
        </span>

        <dropdown-horizontal-transition v-if="dropdownIsVisible && category">
            <dropdown
                v-if="category.services && category.services.length > 0"
                class="!top-[-1px] !left-[calc(100%+20px)]"
                :services="category.services"
                :key="category.id"
                :parent="category"
            />
        </dropdown-horizontal-transition>
    </a>
</template>
