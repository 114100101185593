<script setup lang="ts">
import type Order from '@/interfaces/Order/Order'
import type OrderListItem from '@/interfaces/Order/OrderListItem'
import { computed } from 'vue'
import ListItem from '@/components/Auth/User/Order/ListItem.vue'

type Props = {
    order: Order
}

const { order } = defineProps<Props>()

const listItems = computed<OrderListItem[]>(() => {
    return [
        {
            title: Lang.get('common.conference_link'),
            url: order.meeting_url,
            text: null,
            icon: 'lni-coin',
        },
        {
            title: Lang.get('common.payment_status'),
            url: null,
            text: order.payment_status,
            icon: 'lni-investment',
        },
        {
            title: Lang.get('common.conference_recording_link'),
            url: order.meeting_record_url,
            text: null,
            icon: 'lni-display',
        },
        {
            title: Lang.get('common.conference_recording_password'),
            url: null,
            text: order.meeting_record_password,
            icon: 'lni-lock-alt',
        },
    ]
})
</script>

<template>
    <ul class="space-y-2">
        <list-item
            v-for="listItem in listItems"
            :key="listItem.title"
            :listItem="listItem"
        />
    </ul>
</template>
