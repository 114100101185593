<script setup lang="ts">
import type User from '@/interfaces/User'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type OrderType from '@/interfaces/Order/Order'
import type Specialist from '@/interfaces/Specialist'
import type FetchOrders from '@/interfaces/Responses/FetchOrders'
import type UpdateOrderDetail from '@/interfaces/Responses/UpdateOrderDetail'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import listenEvent from '@/modules/listenEvent'
import appConfig from '@/appConfig'
import Spinner from '@/components/Spinner.vue'
import AppearTransition from '@/components/Transition/AppearTransition.vue'
import UserTabs from '@/components/Auth/User/UserTabs.vue'
import Order from '@/components/Auth/User/Order/Order.vue'

type Props = {
    user: User
}

const { user } = defineProps<Props>()
const loading = ref(true)
const orders = ref<{order: OrderType, specialists: Specialist[]}[]>([])
const nextPageUrl = ref<string | null>(null)
const totalOrders = ref<number | null>(null)
const updatePaymentDetailsInterval = ref<number|null>(null)

onMounted(() => {
    const url = `/api/orders?user_slug=${user.slug}`

    fetchOrders(url)

    listenEvent(appConfig.events.refreshUserOrders, () => {
        loading.value = true
        fetchOrders(url)
    })

    updateOrderDetails()
    updatePaymentDetailsInterval.value = window.setInterval(() => updateOrderDetails(), 30000)
})

function fetchOrders(url: string, refreshOrders = true): void {
    const config: MakeRequestConfig = { url }

    makeRequest<FetchOrders>(config)
        .then(data => {
            nextPageUrl.value = data.orders.next_page_url
            totalOrders.value = data.total

            refreshOrders
                ? orders.value = data.orders.data
                : orders.value.push(...data.orders.data)
        })
        .finally(() => loading.value = false)
}

function loadMoreOrders(): void {
    if (nextPageUrl.value) {
        fetchOrders(`${nextPageUrl.value}&user_slug=${user.slug}`, false)
    }
}

function updateOrderDetails(): void {
    const config: MakeRequestConfig = {
        url: '/api/orders/update-details',
        params: { user_slug: user.slug },
        method: 'post',
    }

    makeRequest<UpdateOrderDetail[]>(config)
        .then(data => updateOrderPaymentDetails(data))
}

function updateOrderPaymentDetails(details: UpdateOrderDetail[]): void {
    if (updatePaymentDetailsInterval.value && details.length === 0) {
        window.clearInterval(updatePaymentDetailsInterval.value)
    }

    details.forEach(detail => {
        orders.value = orders.value.map(order => {
            if (order.order.id === detail.id) {
                order.order.payment_url = detail.payment_url
                order.order.payment_status = detail.payment_status
                order.order.meeting_record_url = detail.meeting_record_url
                order.order.meeting_transcript_url = detail.meeting_transcript_url
                order.order.meeting_record_password = detail.meeting_record_password
            }

            return order
        })
    })
}
</script>

<template>
    <user-tabs :user="user" selected-slug="orders" />

    <appear-transition>
        <div class="container py-8">
            <div v-if="loading" class="text-center mt-5">
                <spinner class="mx-auto" />
            </div>

            <h2
                v-else-if="orders.length === 0"
                class="text-center mt-5 text-xl text-font-second"
            >
                <span class="mr-2">🤔</span>
                {{ $trans.get('common.there_are_no_orders') }}
            </h2>

            <div v-else>
                <h3
                    v-if="totalOrders"
                    class="text-xl mb-5 text-gray-600"
                >
                    {{ $trans.get('common.you_have_in_total') }}:
                    <b>{{ totalOrders }}</b>
                </h3>

                <div class="space-y-3">
                    <order
                        v-for="order in orders"
                        :key="order.order.id"
                        :order="order"
                    />
                </div>
            </div>

            <div class="text-center mt-10">
                <button
                    v-if="nextPageUrl"
                    @click="loadMoreOrders"
                    type="button"
                    class="px-10 py-2 border-2 border-dashed border-gray-400 rounded-full text-gray-700 uppercase hover:border-gray-600 hover:text-black transition-colors"
                >
                    <i
                        class="lni mr-3 font-bold"
                        :class="loading ? 'lni-spinner animate-spin' : 'lni-chevron-down'"
                    ></i>

                    {{ $trans.get('common.load_more') }}
                </button>
            </div>
        </div>
    </appear-transition>
</template>
