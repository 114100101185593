<script setup lang="ts">
import type Order from '@/interfaces/Order/Order'
import type OrderListItem from '@/interfaces/Order/OrderListItem'
import { computed, ref } from 'vue'
import ListItem from './ListItem.vue'

type Props = {
    order: Order
}

const { order } = defineProps<Props>()

const noTranscript = ref<boolean>(order.meeting_transcript_url === '' && order.meeting_record_url !== '')

const listItems = computed<OrderListItem[]>(() => {
    return [
        {
            title: Lang.get('common.conference_link'),
            url: order.meeting_url,
            text: null,
            icon: 'lni-coin',
        },
        {
            title: Lang.get('common.payment_status'),
            url: null,
            text: order.payment_status,
            icon: 'lni-investment',
        }
    ]
})
</script>

<template>
    <ul class="space-y-2">
        <list-item
            v-for="listItem in listItems"
            :key="listItem.title"
            :listItem="listItem"
        />
    </ul>
</template>
