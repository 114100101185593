<script setup lang="ts">
import type SearchHistoryItem from '@/interfaces/DataObjects/SearchHistoryItem'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import SlideTopTransition from '@/components/Transition/SlideTopTransition.vue'
import SearchCache from '@/modules/cache/SearchCache'
import makeRequest from '@/modules/makeRequest'

const searchExamples = ref<SearchHistoryItem[]>([])
const loading = ref<boolean>(true)
const itemClasses = 'flex-shrink-0 bg-main-extra-light py-2 px-6 rounded-full text-sm text-black hover:bg-main-extra-light-hover transition-colors pointer shadow-md'

onMounted(() => fetchSearchExamples())

function fetchSearchExamples(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/search/examples',
    }

    makeRequest<SearchHistoryItem[]>(config)
        .then(data => {
            searchExamples.value = data
        })
        .finally(() => loading.value = false)
}

function redirectToService(title: string, url: string): void {
    SearchCache.add({ title, url })
    window.location.href = url
}
</script>

<template>
    <slide-top-transition>
        <div class="scrollbar flex flex-wrap gap-3 overflow-x-auto py-5 max-w-4xl">
            <div
                v-if="loading"
                class="min-w-[380px] min-h-[36px] animate-pulse"
                :class="itemClasses"
                v-for="i in 3"
                :key="i"
                type="button"
                aria-label="Loading"
            ></div>

            <button
                v-else
                v-for="item in searchExamples"
                :key="item.title"
                @click="redirectToService(item.title, item.url)"
                :class="itemClasses"
                type="button"
                :aria-label="`Go to a ${item.title} page`"
            >
                {{ item.title }}
            </button>
        </div>
    </slide-top-transition>
</template>
