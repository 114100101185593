<script setup lang="ts">
import type Filter from '@/interfaces/Filter/Filter'
import type Service from '@/interfaces/Service'
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref, watch } from 'vue'
import { Listbox } from '@headlessui/vue'
import listenEvent from '@/modules/listenEvent'
import makeRequest from '@/modules/makeRequest'
import appConfig from '@/appConfig'
import FilterButton from '@/components/Filter/Elements/FilterButton.vue'
import FilterOption from '@/components/Filter/Elements/FilterOption.vue'
import FilterOptions from '@/components/Filter/Elements/FilterOptions.vue'
import FilterNumber from '@/components/Filter/Elements/FilterNumber.vue'

interface Emits {
    (e: 'changedItems', ids: number[]): void
}

const emit = defineEmits<Emits>()

const selectedIds = ref<number[]>([])
const selectedServices = ref<Service[]>([])
const categories = ref<Category[]>([])
const loading = ref<boolean>(true)
const allowWatch = ref(false)

onMounted(fetchItems)

watch(selectedServices, () => {
    if (allowWatch.value) {
        let ids: number[] = []
        selectedServices.value.forEach(item => ids.push(item.id))
        emit('changedItems', ids)
    }
})

function fetchItems(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/filters/specialisation',
    }

    makeRequest<Category[]>(config)
        .then(data => setServices(data))
        .finally(() => loading.value = false)
}

function setServices(responseCategories: Category[]): void {
    let selected: Service[] = []

    responseCategories.forEach(category => {
        category.services!.forEach(service => {
            if (selectedIds.value.includes(service.id)) {
                selected.push(service)
            }
        })
    })

    categories.value = responseCategories
    selectedServices.value = selected

    setTimeout(() => allowWatch.value = true, 500)
}

function clearFilter(): void {
    selectedServices.value = []
    selectedIds.value = []
    emit('changedItems', [])
}

listenEvent<Filter>(appConfig.events.activeFilters, filter => {
    selectedIds.value = filter.services
})
</script>

<template>
    <div class="inline-block relative">
        <listbox v-model="selectedServices" multiple>
            <filter-button
                :disabled="selectedServices.length === 0"
                :title="$trans.get('common.specializations')"
                @clearFilter="clearFilter"
            >
                <filter-number v-if="selectedServices.length > 0">
                    {{ selectedServices.length }}
                </filter-number>
            </filter-button>

            <filter-options :loading="loading">
                <div v-for="category in categories" :key="category.id">
                    <div class="p-3">
                        <span class="text-md text-main-dark">{{ category.title }}</span>
                    </div>

                    <div class="border-l ml-3">
                        <filter-option
                            v-if="category.services"
                            v-for="service in category.services"
                            :key="service.id"
                            :value="service"
                        >
                            <div class="flex items-center gap-x-2">
                                <span class="text-sm">{{ service.title }}</span>
                            </div>
                        </filter-option>
                    </div>
                </div>
            </filter-options>
        </listbox>
    </div>
</template>
