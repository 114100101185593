import type Service from '@/interfaces/Service'
import type Category from '@/interfaces/Category'
import { computed } from 'vue'

export default (service?: Service, category?: Category) => {
    const linkTitle = computed<string>(() => {
        if (service) {
            return service.title
        }

        return category ? category.title : ''
    })

    return { linkTitle }
}
