<script setup lang="ts">
import type Service from '@/interfaces/Service'
import type Specialist from '@/interfaces/Specialist'
import type CouponEvent from '@/interfaces/CouponEvent'
import SingleSection from '@/components/SingleSection.vue'
import LargeSolidButton from '@/components/Buttons/LargeSolidButton.vue'
import OrderCache from '@/modules/Cache/OrderCache'
import dispatchEvent from '@/modules/dispatchEvent'
import appConfig from '@/appConfig'
import showToast from '@/modules/showToast'
import { onMounted, ref } from 'vue'
import listenEvent from '@/modules/listenEvent'
import ServiceOfferSpecialists from './ServiceOfferSpecialists.vue'
import NotFound from '@/components/NotFound.vue'
import Tip from '@/components/Tip.vue'
import CouponPopup from "@/components/CouponPopup.vue";

interface Props {
    service: Service
    specialist: Specialist | null
}

const { service, specialist } = defineProps<Props>()
const isSelected = ref<boolean>(false)
const totalServices = ref<number>(OrderCache.get().length)
const couponEvent = ref<CouponEvent>({
    state: false,
    specialistId: null,
    serviceId: null,
});

onMounted(() => setButtonAsSelected())

function setButtonAsSelected(): void {
    if (!specialist) {
        return
    }

    isSelected.value = OrderCache.containsService(specialist.id, service.id)

    listenEvent(appConfig.events.cartIsUpdated, () => {
        isSelected.value = OrderCache.containsService(specialist.id, service.id)
        totalServices.value = OrderCache.countServices()
    })
}

function addServiceToOrder(): void {
    if (!specialist) {
        return
    }

    if (isSelected.value) {
        OrderCache.removeCartItem(specialist.id, service.id)
        showToast({ text: Lang.get('common.service_removed'), success: false })
    } else {
        if (service.coupon_only) {
            couponEvent.value = {
                state : true,
                specialistId : specialist.id,
                serviceId : service.id
            }

        	dispatchEvent(appConfig.events.showCouponPopup, couponEvent.value)
        } else {
            OrderCache.add(specialist.id, service.id)
            showToast({ text: Lang.get('common.service_added') })
        }
    }

    dispatchEvent(appConfig.events.refreshCart)
    isSelected.value = OrderCache.containsService(specialist.id, service.id)
}
</script>

<template>
    <div class="container">
        <slot />
    </div>

    <single-section
        :title="service.title"
        :subtitle="service.about"
        class="!pt-7"
        :isDark="false"
        :useH1Title="true"
    >
        <template #default>
            <div class="text-center md:text-left pb-8 space-x-2">
                <div
                    v-if="specialist && specialist.pivot"
                    class="inline-flex items-center"
                >
                    <tip
                        v-if="specialist.pivot.price && specialist.pivot.price > 0"
                        :content="$trans.get('common.price_from_specialist_tip')"
                        :icon="true"
                    />

                    <span v-if="specialist.pivot.price && specialist.pivot.price > 0"
                          class="text-main-dark ml-3 text-lg lg:text-2xl font-bold">
                        {{ $trans.get('common.price_from_specialist') }}:
                    </span>

                    <div class="flex flex-col ml-3">
                        <b v-if="specialist.pivot.price === 0" class="text-main text-xl lg:text-3xl font-bold">
                            {{ $trans.get('common.free') }}
                        </b>

                        <span v-if="specialist.pivot.price && specialist.pivot.price > 0"
                              class="text-main text-xl lg:text-3xl font-bold">
                            {{ specialist.pivot.price }} €
                        </span>

                        <small v-if="specialist.pivot.price && specialist.pivot.price > 0"
                               class="-mt-2 text-gray-500 text-xs lg:text-sm text-right">
                            {{ $trans.get('common.inc_vat') }}
                        </small>
                    </div>
                </div>
            </div>

            <div
                v-if="specialist"
                class="flex md:inline-flex flex-col md:flex-row gap-5"
            >
                <large-solid-button
                    :title="isSelected ? $trans.get('common.remove_from_order') : $trans.get('common.order_service')"
                    aria-label="Choose service"
                    :icon="isSelected ? 'cross-circle' : 'pointer-right'"
                    :class="isSelected ? 'text-red-700 border-red-700 hover:text-orange-800 hover:border-red-900' : ''"
                    class="min-w-[320px]"
                    @clicked="addServiceToOrder"
                />

                <large-solid-button
                    v-if="totalServices > 0"
                    :title="$trans.get('common.order')"
                    icon="cart"
                    class="min-w-[220px] relative group"
                    @click="dispatchEvent(appConfig.events.showCart, true)"
                >
                    <template #end>
                        <span class="text-main inline-flex group-hover:text-decor group-hover:border-decor items-center justify-center border border-main rounded-full font-bold h-6 w-6 ml-3 text-md">
                            {{ totalServices }}
                        </span>
                    </template>
                </large-solid-button>
            </div>

            <not-found v-else>
                {{ $trans.get('common.no_specialist_for_this_service') }}
            </not-found>
        </template>

        <template #right>
            <a
                v-if="specialist"
                :href="`/specialists/${specialist.slug}`"
                class="group flex justify-center items-center gap-4 lg:gap-7 h-full transition-transform hover:-translate-y-2"
            >
                <img
                    :src="specialist.image_lg"
                    :alt="`Specialist ${specialist.name}`"
                    class="w-24 aspect-square rounded-full shadow-lg"
                    width="356"
                    height="356"
                />

                <div>
                    <h2 class="text-md lg:text-lg">
                        {{ $trans.get('common.selected_specialist') }}
                    </h2>

                    <h3 class="text-2xl lg:text-2xl space-x-1">
                        <span class="text-main-dark">{{ specialist.first }}</span>
                        <b class="text-main">{{ specialist.last }}</b>
                    </h3>

                    <span class="hidden lg:block opacity-0 transition-opacity group-hover:opacity-100 text-font-second">
                        <i class="lni lni-arrow-left mr-1"></i>
                        {{ $trans.get('common.go_to_a_specialist_page') }}
                    </span>
                </div>
            </a>
        </template>
    </single-section>

    <single-section
        :title="$trans.get('common.offered_specialists')"
        :subtitle="$trans.get('common.offered_specialists_for_category')"
        :isDark="true"
    >
        <service-offer-specialists :service="service" />
    </single-section>

    <coupon-popup :service="service" :specialist="specialist"/>
</template>
