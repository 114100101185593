<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import Rate from '@/components/Rate.vue'
import showToast from '@/modules/showToast'
import LanguagesLarge from '@/components/LanguagesLarge.vue'
import PopularServices from './PopularServices.vue'
import ServicesTree from './ServicesTree/ServicesTree.vue'
import SingleSection from '@/components/SingleSection.vue'
import LargeSolidButton from '@/components/Buttons/LargeSolidButton.vue'
import AppearTransition from '@/components/Transition/AppearTransition.vue'
import Tip from '@/components/Tip.vue'
import TruncatedText from '@/components/TruncatedText.vue'
import LazyLoading from '@/components/LazyLoading.vue'

interface Props {
    specialist: Specialist
}

const MAX_ABOUT_LENGTH = 500
const { specialist } = defineProps<Props>()

function chooseServices(): void {
    document.getElementById('all-services-title')!
        .scrollIntoView({ block: 'start', behavior: 'smooth' })

    showToast({
        text: Lang.get('common.choose_services_you_need'),
    })
}
</script>

<template>
    <div class="container">
        <slot />
    </div>

    <appear-transition>
        <single-section class="!pt-7">
            <div class="grid gap-5 grid-cols-1 md:grid-cols-[auto_250px]">
                <div class="flex flex-col lg:flex-row gap-10 items-center">
                    <div>
                        <img :src="specialist.image_lg"
                            :alt="specialist.name"
                            class="rounded-full shadow-md max-w-sm w-[250px] aspect-square"
                            width="356"
                            height="356"
                        />
                    </div>

                    <div class="w-full">
                        <div class="flex flex-col sm:flex-row justify-between items-center gap-2">
                            <div>
                                <h1>
                                    <span class="text-3xl text-main-dark inline-block mr-2">{{ specialist.first }}</span>
                                    <span class="text-3xl text-main font-bold inline-block">{{ specialist.last }}</span>
                                </h1>

                                <div class="text-xl mt-2 text-center md:text-left">
                                    <rate :rate="specialist.rate" :animate="true" class="!gap-x-2"></rate>
                                </div>
                            </div>

                            <div class="flex flex-col md:flex-row items-center gap-2 lg:gap-4">
                                <!-- Price -->
                                <div v-if="specialist.price === 0" class="flex flex-col text-center sm:text-right">
                                    <div class="text-2xl font-black text-main-dark">
                                        {{ $trans.get('common.free') }}
                                    </div>
                                </div>

                                <div v-if="specialist.price !== 0" class="flex flex-col text-center sm:text-right">
                                    <span class="text-xl text-font-second">
                                        {{ $trans.get('common.services_price') }}
                                    </span>

                                    <div class="text-2xl font-black text-main-dark">
                                        {{ $trans.get('common.from') }}
                                        {{ specialist.pretty_price }}
                                    </div>
                                </div>

                                <div v-if="specialist.company">
                                    <tip :content="`${$trans.get('common.company')} ${specialist.company.name}`">
                                        <img
                                            :src="specialist.company.image"
                                            :alt="`${specialist.company.name} company logo`"
                                            width="180"
                                            height="180"
                                            class="w-14 aspect-square mr-3"
                                        />
                                    </tip>
                                </div>
                            </div>
                        </div>

                        <hr class="my-5">

                        <p class="text-font text-lg mt-3">
                            <truncated-text
                                :fullContent="specialist.about"
                                :maxLength="MAX_ABOUT_LENGTH"
                            />
                        </p>
                    </div>
                </div>

                <div class="flex flex-col justify-evenly md:border-l md:pl-4 gap-y-3">
                    <!-- Languages -->
                    <div>
                        <h2 class="text-xl text-font-second mb-4">
                            {{ $trans.get('common.speaks_on_languages') }}
                        </h2>

                        <languages-large :languages="specialist.languages" />
                    </div>

                    <large-solid-button
                        v-if="specialist.services && specialist.services.length !== 0"
                        aria-label="Order the client's services"
                        icon="exit-down"
                        :title="$trans.get('common.services')"
                        class="mt-4 min-w-[250px]"
                        @click="chooseServices"
                    />
                </div>
            </div>
        </single-section>
    </appear-transition>

    <appear-transition>
        <single-section
            :title="$trans.get('common.popular_services')"
            :subtitle="$trans.get('common.most_viewed_services')"
            :isDark="true"
        >
            <popular-services
                v-if="specialist.services"
                :services="specialist.services"
                :specialist="specialist"
            />
        </single-section>
    </appear-transition>

    <lazy-loading>
        <single-section
            :title="$trans.get('common.all_services')"
            :subtitle="$trans.get('common.all_specialist_services')"
            id="all-services-title"
        >
            <services-tree :specialist="specialist" />
        </single-section>
    </lazy-loading>
</template>
