<script setup lang="ts">
import NavIcon from '@/components/Navbar/Menu/NavIcon.vue'
import NavLink from '@/components/Navbar/Menu/NavLink.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import useLanguageSwitch from '@/composables/useLanguageSwitch'

const { switchLanguage } = useLanguageSwitch()
</script>

<template>
    <popover v-slot="{ open }" class="relative">
        <popover-button class="h-full focus:outline-none">
            <nav-link class="hidden lg:block h-full">
                <div class="flex gap-x-3 items-center justify-center w-full">
                    <nav-icon iconName="lni-world" />
                    <span>{{ $locale }}</span>
                </div>
            </nav-link>
        </popover-button>

        <slide-bottom-transition>
            <popover-panel class="absolute right-0 z-10 mt-3 w-screen max-w-[250px] px-4">
                <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="relative flex flex-col gap-1 bg-page p-2">
                        <a
                            v-for="link in $languagesLinks"
                            :key="link.title"
                            href="javascript:"
                            @click="switchLanguage(link.slug)"
                            class="m-0 flex items-center p-2 transition duration-150 ease-in-out hover:bg-main-light focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 rounded-md"
                            :class="{ 'bg-main-extra-light': link.slug === $locale }"
                        >
                            <div class="text-2xl text-page">
                                {{ link.flag }}
                            </div>

                            <div class="ml-3">
                                <p class="text-sm text-gray-500">
                                    {{ link.title }}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </popover-panel>
        </slide-bottom-transition>
    </popover>
</template>
