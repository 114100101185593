<script setup lang="ts">
import type OrderForm from '@/interfaces/Order/OrderForm'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type User from '@/interfaces/User'
import type { CartItemRaw } from '@/interfaces/Responses/FillOrderResponse'
import CartItemType, {CachedCartItem} from '@/interfaces/CartItem'
import CartItemBlockDate from '@/interfaces/CartItemBlockDate'
import {computed, onMounted, ref, watch, watchEffect} from 'vue'
import appConfig from '@/appConfig'
import listenEvent from '@/modules/listenEvent'
import OrderCache from '@/modules/Cache/OrderCache'
import confirmModal from '@/modules/confirmModal'
import dispatchEvent from '@/modules/dispatchEvent'
import makeRequest from '@/modules/makeRequest'
import showToast from '@/modules/showToast'
import LargeSolidButton from '@/components/Buttons/LargeSolidButton.vue'
import DialogPanel from '@/components/Dialog/DialogPanel.vue'
import DialogTitle from '@/components/Dialog/DialogTitle.vue'
import DialogWrapper from '@/components/Dialog/DialogWrapper.vue'
import FilledButton from '@/components/Buttons/FilledButton.vue'
import CartForm from './Form/CartForm.vue'
import Spinner from '@/components/Spinner.vue'
import CartItem from './CartItem.vue'
import convertServerResponseToCartItems from '@/modules/convertServerResponseToCartItems'
import hasOldCart from '@/modules/cart/hasOldCart'
import SlideLeftTransition from '@/components/Transition/SlideLeftTransition.vue'
import RequiredFieldsMessage from '@/components/Form/RequiredFieldsMessage.vue'
import WarningMessage from '@/components/Cart/WarningMessage.vue'

type Props = {
    user: User | null
}

const { user } = defineProps<Props>()

const cartItems = ref<CartItemType[]>([])
const loading = ref(false)
const showCart = ref(false)
const agreesToTermsOfUse = ref(false)
const form = ref<OrderForm>({
    first: '',
    last: '',
    email: '',
    phone: '',
    comment: '',
    vat: null
})
const blockItemDate = ref<CartItemBlockDate[]>([])
const typeWarningMessageStatus = ref<boolean>(true);

onMounted(() => {
    refreshOrder()

    if (user) {
        form.value.first = user.first || ''
        form.value.last = user.last || ''
        form.value.email = user.email || ''
        form.value.phone = user.phone || ''
    }
})

watch(cartItems, (newCartItems) => {
  typeWarningMessageStatus.value = !newCartItems.every(item => item.type === 'written');
}, { deep: true });

listenEvent(appConfig.events.refreshCart, refreshOrder)

listenEvent<{ first: string, last: string }>(appConfig.events.userIsUpdated, name => {
    form.value.first = name.first
    form.value.last = name.last
})

listenEvent<boolean>(appConfig.events.showCart, show => showCart.value = show)

const totalServices = computed(() => {
    return cartItems.value.reduce((prev, cartItem) => {
        return ++prev
    }, 0)
})

async function storeOrder(): Promise<void> {
    const cachedCartItems = OrderCache.get()

    if (loading.value || !cachedCartItems)
        return

    if (!agreesToTermsOfUse.value) {
        showToast({
            text: Lang.get('common.you_can_send_order_only_after_reading'),
            success: false,
        })
        return
    }

    const confirmed = await confirmModal({
        title: Lang.get('common.should_we_create_order'),
        text: Lang.get('common.time_for_paying_for_order'),
    })

    if (!confirmed) {
        return
    }

    const config: MakeRequestConfig = {
        url: '/api/orders',
        method: 'post',
        data: {
            ...form.value,
            cart_items: cachedCartItems,
        },
    }

    loading.value = true

    makeRequest(config)
        .then(() => {
            dispatchEvent(appConfig.events.refreshUserOrders)

            OrderCache.removeAll()

            showCart.value = false
            form.value.comment = ''

            window.location.href = '/user/orders/created'
        })
        .finally(() => loading.value = false)
}

function refreshOrder(): void {
    const cachedCartItems = OrderCache.get()

    if (cachedCartItems.length === 0) {
        dispatchEvent(appConfig.events.cartIsUpdated)
        cartItems.value = []
        return
    }

    if (hasOldCart(cachedCartItems)) {
        OrderCache.removeAll()
        showToast({ text: Lang.get('common.local_storage_was_cleared') })
        dispatchEvent(appConfig.events.cartIsUpdated)
        return
    }

    fetchOrder(cachedCartItems)
}

function fetchOrder(cachedCartItems: CachedCartItem[]): void {
    loading.value = true

    const param = JSON.stringify(cachedCartItems)

    const config: MakeRequestConfig = {
        url: `/api/orders/fill?cart_items=${param}`,
    }

    makeRequest<CartItemRaw[]>(config)
        .then(data => {
            cartItems.value = convertServerResponseToCartItems(data)
            dispatchEvent(appConfig.events.cartIsUpdated)
        })
        .catch(handleFillOrderError)
        .finally(() => loading.value = false)
}

function handleFillOrderError(err: any): void {
    OrderCache.removeAll()
}

function setIsOpen(value: boolean) {
    showCart.value = value
}

function typeWrittenAgreed() {
  cartItems.value.forEach(item => {
    item.type = 'written';
  });
}

async function deleteOrder(specialistId: number, serviceId: number): Promise<void> {
    const confirmed = await confirmModal({
        text: Lang.get('common.remove_consultation_from_the_order'),
    })

    if (!confirmed) {
        return
    }

    OrderCache.removeCartItem(specialistId, serviceId)
    refreshOrder()
}
</script>

<template>
    <div v-if="cartItems">
        <slide-left-transition>
            <large-solid-button
                v-if="cartItems.length > 0"
                class="fixed shadow-none !text-white font-light right-0 top-[55px] lg:top-[80px] z-30 bg-main-dark hover:bg-main-dark-hover rounded-r-none pr-4 focus:outline-none ~dow-none border-l-2 border-y-2 border-r-0 border-white/30 hover:text-white"
                icon="cart-full"
                :title="$trans.get('common.order')"
                @click="showCart = true"
                aria-label="Show cart button"
            >
                <template #end>
                    <span class="inline-flex items-center justify-center bg-page text-main-hover rounded-full font-bold h-5 w-5 ml-3 text-sm">
                        <i v-if="loading" class="lni lni-spinner animate-spin" aria-hidden="true"></i>
                        <span v-else>{{ totalServices }}</span>
                    </span>
                </template>
            </large-solid-button>
        </slide-left-transition>

        <dialog-wrapper @close="setIsOpen(false)" :open="showCart">
            <div class="fixed inset-0 flex items-center justify-center p-4">
                <div class="flex min-h-full items-center justify-center">
                    <dialog-panel @closeModal="setIsOpen(false)">
                        <dialog-title class="mb-5">{{ $trans.get('common.application') }}</dialog-title>

                        <div
                            class="grid grid-cols-1 md:grid-cols-2 gap-5"
                            :class="cartItems.length === 1 ? 'md:grid-cols-1' : 'md:grid-cols-2'"
                        >
                            <template v-for="cartItem in cartItems" :key="cartItem.title">
                                <cart-item
                                    :cartItem="cartItem"
                                    :blockItemDate="blockItemDate"
                                    @deleteOrder="deleteOrder"
                                />
                            </template>

                            <warning-message
                                @agreed="typeWrittenAgreed"
                                :typeWarningMessageStatus="typeWarningMessageStatus"
                            />

                            <cart-form :form="form" :user="user" />
                        </div>

                        <div v-if="!user" class="mt-6">
                            <span class="inline-flex justify-center items-center border border-current rounded-full mr-2 w-5 h-5">
                                <i class="lni lni-information text-xs" aria-hidden="true"></i>
                            </span>

                            <span class="text-font-second">
                                {{ $trans.get('common.you_will_be_registered') }}
                            </span>
                        </div>

                        <div class="flex gap-3 mt-4">
                            <input v-model="agreesToTermsOfUse" id="privacy" type="checkbox">

                            <label for="privacy" class="leading-5">
                                <span class="mr-1">{{ $trans.get('common.i_agree_that_ive_read') }}</span>

                                <a
                                    href="https://www.nalog.nl/algemene-voorwaarden-nl/"
                                    target="_blank"
                                    class="text-main underline"
                                >
                                    {{ $trans.get('common.terms_of_use') }}
                                </a>
                            </label>
                        </div>

                        <div class="mt-6">
                            <div v-if="loading" class="flex gap-4 items-center">
                                <spinner class="w-8 h-8" />
                                <h2>{{ $trans.get('common.processing_your_order') }} ...</h2>
                            </div>

                            <filled-button
                                v-else
                                @clicked="storeOrder"
                                class="w-full md:w-auto"
                                :class="{ 'opacity-50 cursor-not-allowed': !agreesToTermsOfUse }"
                            >
                                <i class="lni lni-rocket mr-3 text-xl"></i>
                                <span class="text-[1.2em]">{{ $trans.get('common.send_application') }}</span>
                            </filled-button>
                        </div>

                        <div class="mt-5 md:mt-0">
                            <required-fields-message />
                        </div>
                    </dialog-panel>
                </div>
            </div>
        </dialog-wrapper>
    </div>
</template>
