<script setup lang="ts">
import { onMounted } from 'vue'
import animateRateItems from '@/modules/animateRateItems'

interface Props {
    rate: number
    animate?: boolean
}

const { rate, animate = false } = defineProps<Props>()

onMounted(() => {
    if (animate) {
        animateRateItems('.stars-rate-elements')
    }
})
</script>

<template>
    <div class="inline-flex gap-x-0.5">
        <div v-for="index in rate" :key="index" class="w-[16px] h-[16px] inline-flex">
            <i
                class="lni lni-star-filled text-amber-600 drop-shadow-md transition-transform stars-rate-elements"
                :class="{
                    'opacity-75': index === 1,
                    'opacity-80': index === 2,
                    'opacity-90': index === 3,
                    'opacity-95': index === 4,
                    'opacity-100': index === 5,
                }"
                aria-hidden="true"
            ></i>️
        </div>

        <div v-for="index in 5 - rate" :key="index" class="w-[16px] h-[16px] inline-flex">
            <i class="lni lni-star-filled text-gray-200" aria-hidden="true"></i>️
        </div>
    </div>
</template>
