<script setup lang="ts">
import type Order from '@/interfaces/Order/Order'
import type Specialist from '@/interfaces/Specialist'
import OrderTitle from '@/components/Auth/User/Order/OrderTitle.vue'
import OrderService from './OrderService.vue'
import OrderSpec from './OrderSpec.vue'
import ListItems from '@/components/Auth/User/Order/ListItems.vue'
import ShowOptions from '@/components/Auth/User/Order/ShowOptions.vue'
import { computed } from 'vue'
import Rate from '@/components/Rate.vue'
import Tip from '@/components/Tip.vue'

type Props = {
    order: { order: Order, specialists: Specialist[] }
}

const { order } = defineProps<Props>()

const orderName = order.order.name.includes('№') ? order.order.name : '№' + order.order.name;

const orderTitle = computed(() => {
    return `${Lang.get('common.order')} ${orderName} (${Lang.get(`common.${order.order.type}`)})`
})
</script>

<template>
    <div class="relative">
        <show-options :order="order.order" />

        <article class="grid grid-cols-1 lg:grid-cols-2 gap-6 bg-page rounded-md p-6 shadow-md">
            <div>
                <h2 class="text-2xl text-main-dark">
                    {{ orderTitle }}
                </h2>

                <ul class="flex flex-col gap-4 space-y-2 my-5">
                    <order-service
                        v-if="order.order.services"
                        v-for="service in order.order.services"
                        :key="service.id"
                        :service="service"
                        :order-type="order.order.type"
                        :order-coupon="order.order.coupon"
                    />
                </ul>
            </div>

            <div class="flex flex-col justify-between">
                <div class="md:min-w-[360px] flex flex-col justify-between gap-3">
                    <div>
                        <h2 class="text-2xl text-main-dark">
                            {{ $trans.get('common.specialist') }}
                        </h2>

                        <ul class="space-y-2 my-5 py-5 border-y border-border">
                            <order-spec
                                v-for="specialist in order.specialists"
                                :key="specialist.id"
                                :spec="specialist"
                            />
                        </ul>
                    </div>
                </div>

                <list-items :order="order.order" />

                <div class="flex justify-between mt-4 pt-4 border-t border-border">
                    <h3 class="flex flex-col md:flex-row gap-1 text-gray-500">
                        <div>
                            <div class="lni lni-calendar mr-2"></div>
                            <span>{{ $trans.get('common.creation_date') }}:</span>
                        </div>
                        <span>{{ order.order.pretty_created_at }}</span>
                    </h3>

                    <tip v-if="order.order.rate" :content="$trans.get('common.your_rate_for_order')">
                        <rate :rate="order.order.rate" />
                    </tip>
                </div>
            </div>
        </article>
    </div>
</template>
