<script setup lang="ts">
import type Service from '@/interfaces/Service'
import type Specialist from '@/interfaces/Specialist'
import type { PricePivot } from '@/interfaces/Service'
import { computed } from 'vue'
import VueHorizontal from 'vue-horizontal'
import ServiceCard from '@/components/Service/ServiceCard.vue'
import NotFound from '@/components/NotFound.vue'

interface Props {
    services: Service[]
    specialist: Specialist
}

const { services, specialist } = defineProps<Props>()

const popularServices = computed<Service[]>(() => {
    return services.sort((a, b) => a.views < b.views ? 1 : -1)
})
</script>

<template>
    <div v-if="popularServices.length > 0">
        <vue-horizontal v-if="popularServices.length > 0">
            <div
                v-for="service in popularServices"
                :key="service.id"
                class="w-full max-w-[293px] mr-5"
            >
                <service-card
                    :service="service"
                    :price="service.pivot ? (service.pivot as PricePivot).price : service.price"
                    :specialistSlug="specialist.slug"
                    class="h-full"
                />
            </div>
        </vue-horizontal>
    </div>

    <div v-else class="px-5">
        <not-found>{{ $trans.get('common.services_not_found') }}</not-found>
    </div>
</template>
