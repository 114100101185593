<script setup lang="ts">
import { ref } from 'vue'
import listenEvent from '@/modules/listenEvent'
import app from '@/appConfig'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import MobileNavMenu from '@/components/Navbar/Menu/Mobile/MobileNavMenu.vue'
import MobileSearch from '@/components/Search/MobileSearch.vue'

const visible = ref(false)

listenEvent<boolean>(app.events.toggleSidebar, openSidebar => {
    visible.value = openSidebar
})
</script>

<template>
    <slide-bottom-transition>
        <div
            v-if="visible"
            class="sticky top-[var(--page-top-sm-mobile)] block lg:hidden bg-page h-auto right-0 left-0 z-40"
        >
            <mobile-search />
            <mobile-nav-menu />
        </div>
    </slide-bottom-transition>
</template>
