import type SearchData from '../interfaces/DataObjects/SearchData'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type SearchResponse from '@/interfaces/Responses/SearchResponse'
import makeRequest from '@/modules/makeRequest'
import { reactive } from 'vue'
import { debounce } from 'lodash'
import SearchCache from '../modules/Cache/SearchCache'

export default () => {
    const search = reactive<SearchData>({
        services: [],
        specialists: [],
        categories: [],
        query: '',
        loading: false,
        history: [],
    })

    function showSearchHistory(): void {
        if (search.query !== '')
            return

        search.history = SearchCache.get()
    }

    const makeRequestToFetchItems = debounce<(q: string) => void>((q) => {
        if (q === '') {
            search.services = []
            search.specialists = []
            search.categories = []
            return
        }

        if (q.length === 1) {
            return
        }

        const config: MakeRequestConfig = {
            url: `/api/search/${q}`,
        }

        makeRequest<SearchResponse>(config)
            .then(data => {
                search.services = data.services
                search.specialists = data.specialists
                search.categories = data.categories
            })
    }, 300)

    function fetchItems(): void {
        makeRequestToFetchItems(search.query)
    }

    function saveItemToHistory(title: string, url: string): void {
        SearchCache.add({ title, url })
    }

    function submitSearchForm(): void {
        if (search.query === '' || search.query.length === 1) {
            return
        }

        const uri = getResultsPageUrl()

        saveItemToHistory(search.query, uri)

        window.location.href = uri
    }

    function getResultsPageUrl(): string {
        const servicesLen = search.services.length
        const categoriesLen = search.categories.length
        const specialistsLen = search.specialists.length

        if (servicesLen > categoriesLen && servicesLen > specialistsLen) {
            return `/services?query=${search.query}`
        }

        if (categoriesLen > servicesLen && categoriesLen > specialistsLen) {
            return `/categories?query=${search.query}`
        }

        return `/specialists?query=${search.query}`
    }

    return {
        search,
        fetchItems,
        showSearchHistory,
        saveItemToHistory,
        submitSearchForm,
    }
}
