<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import CategoryTreeItem from './CategoryTreeItem.vue'
import NotFound from '@/components/NotFound.vue'
import CouponPopup from "@/components/CouponPopup.vue";

interface Props {
    specialist: Specialist
}

const { specialist } = defineProps<Props>()
const categories = ref<Category[]>([])

onMounted(() => {
    fetchSpecializations()
})

function fetchSpecializations(): void {
    const config: MakeRequestConfig = {
        url: `/api/specialists/specializations/${specialist.id}`,
    }

    makeRequest<Category[]>(config)
        .then(data => categories.value = data)
}
</script>

<template>
    <div v-if="categories.length > 0" class="border border-border p-2 md:p-7 rounded-lg">
        <category-tree-item
            v-for="(category, index) in categories"
            :key="category.id"
            :category="category"
            :specialist="specialist"
            :index="index"
        />
    </div>

    <not-found v-else>
        {{ $trans.get('common.services_not_found') }}
    </not-found>
</template>
