<template>
    <transition
        enter-active-class="transition-transform duration-300"
        enter-from-class="scale-y-0"
        enter-to-class="-scale-y-5"
        leave-active-class="transition-transform duration-300"
        leave-from-class="-scale-y-5"
        leave-to-class="scale-y-0"
    >
        <slot />
    </transition>
</template>
