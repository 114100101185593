<script setup lang="ts">
import type SearchData from '@/interfaces/DataObjects/SearchData'
import NotFound from './NotFound.vue'
import DropdownItemsSection from './DropdownItemsSection.vue'
import History from './History.vue'
import linkTo from '@/modules/linkTo'
import { computed } from 'vue'

interface Props {
    search: SearchData
    classes?: string
}

const { search, classes = '' } = defineProps<Props>()

let addClasses = 'bg-page h-[350px] lg:h-auto overflow-y-auto scrollbar shadow-xl p-6 top-[45px] lg:top-[calc(100%+5px)] border-t bg-page absolute left-0 right-0 z-50 lg:rounded-lg rounded-none'
addClasses += `${addClasses} ${classes}`

const sectionsFound = computed<number>(() => {
    const sections = [
        search.services,
        search.categories,
        search.specialists,
    ]

    return sections.filter(sec => sec.length > 0).length
})
</script>

<template>
    <div :class="addClasses">
        <history v-if="search.history.length > 0" :history="search.history" />

        <div v-else>
            <not-found v-if="sectionsFound === 0" />

            <div v-else>
                <div
                    class="grid grid-cols-1 justify-between relative gap-5"
                    :class="{
                        'md:grid-cols-2': sectionsFound === 2,
                        'md:grid-cols-3': sectionsFound === 3,
                    }"
                >
                    <dropdown-items-section
                        v-if="search.services.length > 0"
                       :heading="$trans.get('common.services')"
                       :items="search.services"
                       :link-url="linkTo('services/{slug}')"
                    />

                    <dropdown-items-section
                        v-if="search.categories.length > 0"
                        :heading="$trans.get('common.categories')"
                        :items="search.categories"
                        :link-url="linkTo('categories/{slug}')"
                    />

                    <dropdown-items-section
                        v-if="search.specialists.length > 0"
                        :heading="$trans.get('common.specialists')"
                        :items="search.specialists"
                        :link-url="linkTo('specialists/{slug}')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
