export default {
    // Custom JavaScript events
    events: {
        toggleSidebar: 'togglesidebar',
        activeFilters: 'activelanguages',
        refreshCart: 'refreshcart',
        cartIsUpdated: 'cartisupdated',
        userIsUpdated: 'userisupdated',
        refreshUserOrders: 'refreshuserorders',
        showCart: 'showservicescart',
        showCouponPopup: 'showcouponpopup'
    },

    // Local storage keys
    storageKeys: {
        searchHistory: 'search-history',
        order: 'order',
        orderComment: 'order-comment',
        orderCoupons: 'order-coupons',
        viewedService: 'viewed-service',
        contactUsTimer: 'contact-us-timer',
    },

    cookieKeys: {
        lang: 'lang',
    },
}
