<script setup lang="ts">
import useSearch from '@/composables/useSearch'
import Dropdown from './Dropdown.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'

interface Props {
    showButton?: boolean
    dropdownClasses?: string
    inputClasses?: string
}

const { showButton = false, dropdownClasses = '', inputClasses = '' } = defineProps<Props>()
const { search, showSearchHistory, fetchItems, submitSearchForm } = useSearch()

function clearItems(): void {
    search.query = ''
    search.history = []
}
</script>

<template>
    <slide-bottom-transition>
        <form
            @submit.prevent="submitSearchForm"
            class="flex w-full items-center h-auto bg-transparent lg:bg-page rounded-full flex-col lg:flex-row"
        >
            <label class="flex flex-1 w-full h-full items-center bg-page rounded-full">
                <i class="lni lni-search-alt ml-5 mr-3 lg:mx-8 text-gray-400 font-bold" aria-hidden="true"></i>

                <input
                    type="search"
                    class="outline-none w-full bg-transparent pr-3 h-[45px] lg:h-auto"
                    :class="inputClasses"
                    :placeholder="$trans.get('common.search_for') + '...'"
                    v-model="search.query"
                    @focus="showSearchHistory"
                    @focusout="clearItems"
                    @input="e => {
                        search.query = e.target.value
                        fetchItems()
                    }"
                    @keydown="search.history = []"
                    autocomplete="off"
                />
            </label>

            <button
                v-if="showButton"
                type="submit"
                class="bg-main transition-colors hover:bg-main-hover rounded-full h-[45px] lg:h-full px-12 text-white font-bold uppercase text-xs w-full lg:w-auto lg:mt-0 mt-3 shadow-md"
            >
                {{ $trans.get('common.find') }}
            </button>

            <SlideBottomTransition>
                <dropdown
                    v-if="(search.query.length > 1 && !search.loading) || search.history.length !== 0"
                    :classes="dropdownClasses"
                    :search="search"
                ></dropdown>
            </SlideBottomTransition>
        </form>
    </slide-bottom-transition>
</template>

