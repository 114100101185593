<script setup lang="ts">
import type Order from '@/interfaces/Order/Order'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { Menu, MenuButton } from '@headlessui/vue'
import confirmModal from '@/modules/confirmModal'
import dispatchEvent from '@/modules/dispatchEvent'
import makeRequest from '@/modules/makeRequest'
import showToast from '@/modules/showToast'
import appConfig from '@/appConfig'
import MenuItems from '@/components/Dropdown/MenuItems.vue'
import MenuItem from '@/components/Dropdown/MenuItem.vue'

type Props = {
    order: Order
}

const { order } = defineProps<Props>()

async function deleteOrder() {
    const confirmed = await confirmModal({
        text: Lang.get('common.should_we_delete_order'),
    })

    if (!confirmed)
        return

    const requestConf: MakeRequestConfig = {
        method: 'delete',
        url: `/api/orders/${order.slug}`,
    }

    const response = await makeRequest<{ deleted: boolean }>(requestConf)

    if (!response.deleted)
        return

    dispatchEvent(appConfig.events.refreshUserOrders)

    showToast({ text: Lang.get('common.order_is_deleted') })
}
</script>

<template>
    <Menu>
        <menu-button class="absolute right-6 top-3">
            <i class="lni lni-more-alt text-2xl text-gray-600"></i>
        </menu-button>

        <menu-items>
            <menu-item @click="deleteOrder" icon="lni-ban" iconColor="text-red-500">
                {{ $trans.get('common.delete_the_order') }}
            </menu-item>
        </menu-items>
    </Menu>
</template>
