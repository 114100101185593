export default (first: string | null, last: string | null, limitChars?: number): string => {
    first = first || ''
    last = last || ''

    const name = `${first} ${last}`.trim()

    if (name.length === 0) {
        return Lang.get('common.unknown')
    }

    if (limitChars && name.length > limitChars) {
        return name.substring(0, limitChars) + '...'
    }

    return name
}
