<script setup lang="ts">
import { ref, watch } from 'vue'
import ShowPasswordButton from '@/components/Buttons/ShowPasswordButton.vue'
import FormLabel from '@/components/Form/FormLabel.vue'

type Props = {
    name: string
    label: string
    value?: string
    type?: string
    disabled?: boolean
    warning?: string
    isRequired?: boolean
    placeholder?: string
}

type Emits = {
    (e: 'changed', value: string): void
}

const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    value: '',
    disabled: false,
    isRequired: false,
})

const emit = defineEmits<Emits>()
const inputValue = ref(props.value)

watch(() => props.value, () => {
    inputValue.value = props.value
})
</script>

<template>
    <div class="w-full">
        <form-label
            :for="props.name"
            :is-required="props.isRequired"
            :warning="props.warning"
        >
            {{ props.label }}
        </form-label>

        <div class="relative">
            <input
                :type="props.type"
                :id="props.name"
                :name="props.name"
                class="border py-2 pl-3 pr-9 rounded-md text-md w-full focus:outline-main"
                v-model="inputValue"
                @input="emit('changed', inputValue)"
                :disabled="disabled"
                :class="{ 'opacity-60': disabled }"
                :placeholder="props.placeholder"
            />

            <show-password-button
                v-if="props.type === 'password'"
                :inputId="props.name"
            ></show-password-button>
        </div>
    </div>
</template>
