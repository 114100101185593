import type Filter from '../interfaces/Filter/Filter'

/**
 * Returns URL path
 */
export default (filters: Filter): string | null => {
    let params: string[] = []

    params.push(`sort=${filters.sort}`)

    if (filters.languages.length > 0) {
        params.push(`languages=${filters.languages.join(',')}`)
    }

    if (filters.services.length > 0) {
        params.push(`services=${filters.services.join(',')}`)
    }

    if (filters.categories.length > 0) {
        params.push(`categories=${filters.categories.join(',')}`)
    }

    if (filters.query.length > 0) {
        params.push(`query=${filters.query}`)
    }

    return '?' + params.join('&')
}
