<script setup lang="ts">
import useLanguageSwitch from '@/composables/useLanguageSwitch'
import { onMounted, ref } from 'vue'
import appConfig from '@/appConfig'
import Cookies from 'js-cookie'

const { switchLanguage } = useLanguageSwitch()
const isVisible = ref(false)

type Props = {
    userLang: string
}

const { userLang } = defineProps<Props>()

onMounted(() => showPopupIfNecessary())

function showPopupIfNecessary(): void {
    const userLocale = navigator.language.split('-')[0]
    const langFromCookie = Cookies.get(appConfig.cookieKeys.lang)

    if (userLocale === DefaultLocale || langFromCookie) {
        return
    }

    if (userLang !== '') {
        switchLanguage(userLang)
        return
    }

    isVisible.value = true
}
</script>

<template>
    <aside
        v-if="isVisible"
        class="fixed top-0 left-0 right-0 bottom-0 bg-black/50 z-50 flex items-center justify-center"
    >
        <div class="bg-page p-8 rounded-xl shadow-md">
            <h2 class="text-2xl text-center mb-5">
                <i class="lni lni-world mr-2" aria-hidden="true"></i>
                <span>Choose language</span>
            </h2>

            <ul class="grid grid-cols-2 md:grid-cols-3 gap-4">
                <li
                    @click="switchLanguage(lang.slug)"
                    v-for="lang in $languagesLinks"
                    :key="lang.slug"
                    class="border inline-flex flex-col items-center justify-center rounded-lg w-32 h-32 hover:bg-main-extra-light cursor-pointer transition-colors"
                    :class="{ 'border-main': $locale === lang.slug }"
                    :aria-label="`${$trans.get('common.choose')} ${lang.title} ${$trans.get('common.language')}`"
                >
                    <span class="text-5xl">{{ lang.flag }}</span>
                    <h3>{{ lang.title }}</h3>
                </li>
            </ul>
        </div>
    </aside>
</template>
