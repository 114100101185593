import makeRequest from '@/modules/makeRequest'
import confirmModal from '@/modules/confirmModal'
import linkTo from '@/modules/linkTo'

export default async (): Promise<void> => {
    const answer = await confirmModal({text: Lang.get('common.want_to_logout')})

    if (answer) {
        makeRequest({ url: '/logout', method: 'post' })
            .then(() => window.location.href = linkTo('login'))
    }
}
