<script setup lang="ts">
import type Service from '@/interfaces/Service'
import type { PricePivot } from '@/interfaces/Service'
import { computed, ref } from 'vue'
import ServiceTag from './ServiceTag.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import linkTo from '@/modules/linkTo'

interface Props {
    services: Service[]
}

const props = defineProps<Props>()
const additionalServiceAreVisible = ref<boolean>(false)

const mainServices = computed<Service[]>(() => {
    return props.services.filter(service => service.pivot && (service.pivot as PricePivot).is_main)
})

const additionalServices = computed<Service[]>(() => {
    return props.services.filter(service => service.pivot && !(service.pivot as PricePivot).is_main)
})
</script>

<template>
    <div class="flex gap-1 flex-wrap">
        <service-tag
            v-for="service in mainServices"
            :key="service.id"
            :href="linkTo(`specialists?services=${service.id}`)"
        >
            {{ service.title }}
        </service-tag>

        <service-tag
            v-if="additionalServices.length > 0"
            @click="additionalServiceAreVisible = !additionalServiceAreVisible"
            class="border-dashed"
        >
            <span v-if="additionalServiceAreVisible">
                {{ $trans.get('common.hide') }}...
            </span>
            <span v-else>
                {{ $trans.get('common.show_more') }}...
            </span>
        </service-tag>

        <slide-bottom-transition>
            <div v-if="additionalServiceAreVisible" class="flex gap-1 flex-wrap">
                <service-tag
                    v-for="service in additionalServices"
                    :key="service.id"
                    :href="linkTo(`specialists?services=${service.id}`)"
                >
                    {{ service.title }}
                </service-tag>
            </div>
        </slide-bottom-transition>
    </div>
</template>
