<script setup lang="ts">
import { onMounted, ref } from 'vue'
import listenEvent from '@/modules/listenEvent'
import appConfig from '@/appConfig'
import getUserName from '@/modules/getUserName'
import type Auth from '@/interfaces/Auth'

type Props = {
    auth: Auth
    profilePhoto?: string
}

const { auth, profilePhoto } = defineProps<Props>()
const userName = ref(getUserName(auth.first, auth.last))

onMounted(() => {
    listenEvent<{ first: string, last: string }>(appConfig.events.userIsUpdated, name => {
        userName.value = getUserName(name.first, name.last)
    })
})
</script>

<template>
    <div class="bg-gradient bg-gradient-to-br from-main-dark to-main px-0 md:px-20">
        <div class="container">
            <div class="pt-16 pb-12 text-white">
                <div class="flex items-center gap-6">
                    <div class="border-2 border-white rounded-full inline-flex w-16 h-16 items-center justify-center bg-white/10">
                        <img
                            v-if="profilePhoto"
                            :src="profilePhoto"
                            alt="Profile photo"
                            aria-hidden="true"
                            class="rounded-full"
                        />
                        <i
                            v-else
                            class="lni lni-user text-3xl font-bold"
                            aria-hidden="true"
                        ></i>
                    </div>

                    <div>
                        <div v-if="userName" class="flex flex-col">
                            <h2 class="text-3xl">{{ userName }}</h2>

                            <h3 class="text-sm opacity-90">
                                {{ $trans.get('common.on_site') }}: {{ auth.pretty_created_at }}
                            </h3>
                        </div>

                        <h2 v-else class="space-x-2 text-3xl">
                            {{ $trans.get('common.unknown') }}
                        </h2>
                    </div>
                </div>
            </div>

            <div class="flex gap-2">
                <slot />
            </div>
        </div>
    </div>
</template>
