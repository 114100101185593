import { isEqual } from 'lodash'
import type SearchHistoryItem from '@/interfaces/DataObjects/SearchHistoryItem'
import appConfig from '@/appConfig'

export default class {
    public static add(item: SearchHistoryItem): void {
        let oldItems = this.get().filter(oldItem => !isEqual(oldItem, item))
        oldItems.push(item)

        const newItems = oldItems.reverse().splice(0, 5).reverse()

        localStorage.setItem(appConfig.storageKeys.searchHistory, JSON.stringify(newItems))
    }

    public static get(): SearchHistoryItem[] {
        const history = localStorage.getItem(appConfig.storageKeys.searchHistory)
        return history ? JSON.parse(history) : []
    }
}
