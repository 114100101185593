import type UrlParams from '../interfaces/UrlParams'

export default (urlPath: string): UrlParams => {
    const params = new URLSearchParams(urlPath)
    const result: UrlParams = {}

    params.forEach((param, key) => {
        result[key] = param
    })

    return result
}