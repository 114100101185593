<script setup lang="ts">
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type OrderType from '@/interfaces/Order/Order'
import type Specialist from '@/interfaces/Specialist'
import type SpecFetchOrders from '@/interfaces/Responses/SpecFetchOrders'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import Spinner from '@/components/Spinner.vue'
import AppearTransition from '@/components/Transition/AppearTransition.vue'
import Order from '@/components/Auth/Spec/Order/Order.vue'
import SpecTabs from '@/components/Auth/Spec/SpecTabs.vue'

type Props = {
    spec: Specialist
}

const { spec } = defineProps<Props>()
const loading = ref(true)
const orders = ref<OrderType[]>([])
const nextPageUrl = ref<string | null>(null)
const totalOrders = ref<number | null>(null)

onMounted(() => {
    fetchOrders(`/api/spec-orders?spec_slug=${spec.slug}`)
})

function fetchOrders(url: string, refreshOrders = true): void {
    const config: MakeRequestConfig = { url }

    makeRequest<SpecFetchOrders>(config)
        .then(data => {
            nextPageUrl.value = data.orders.next_page_url
            totalOrders.value = data.total

            refreshOrders
                ? orders.value = data.orders.data
                : orders.value.push(...data.orders.data)
        })
        .finally(() => loading.value = false)
}

function loadMoreOrders(): void {
    if (nextPageUrl.value) {
        fetchOrders(`${nextPageUrl.value}&spec_slug=${spec.slug}`, false)
    }
}
</script>

<template>
    <spec-tabs :spec="spec" selected-slug="orders" />

    <appear-transition>
        <div class="container py-8">
            <div v-if="loading" class="text-center mt-5">
                <spinner class="mx-auto" />
            </div>

            <h2
                v-else-if="orders.length === 0"
                class="text-center mt-5 text-xl text-font-second"
            >
                <span class="mr-2">🤔</span>
                {{ $trans.get('common.there_are_no_orders') }}
            </h2>

            <div v-else>
                <h3
                    v-if="totalOrders"
                    class="text-xl mb-5 text-gray-600"
                >
                    {{ $trans.get('common.you_have_in_total') }}:
                    <b>{{ totalOrders }}</b>
                </h3>

                <div class="space-y-3">
                    <order
                        v-for="order in orders"
                        :key="order.id"
                        :order="order"
                    />
                </div>
            </div>

            <div class="text-center mt-10">
                <button
                    v-if="nextPageUrl"
                    @click="loadMoreOrders"
                    type="button"
                    class="px-10 py-2 border-2 border-dashed border-gray-400 rounded-full text-gray-700 uppercase hover:border-gray-600 hover:text-black transition-colors"
                >
                    <i
                        class="lni mr-3 font-bold"
                        :class="loading ? 'lni-spinner animate-spin' : 'lni-chevron-down'"
                    ></i>

                    {{ $trans.get('common.load_more') }}
                </button>
            </div>
        </div>
    </appear-transition>
</template>
