<script setup lang="ts">
interface Props {
    href?: string
    classes?: string
}

const { href, classes = '' } = defineProps<Props>()
let addClasses = 'w-full text-[1.1em] transition-colors hover:bg-page-hover flex justify-between items-center p-5 text-main-dark font-bold border-b last:border-none relative'

if (classes) {
    addClasses = `${addClasses} ${classes}`
}
</script>

<template>
    <a
        v-if="href"
        :href="href"
        :class="addClasses"
        aria-label="Navbar link"
    >
        <slot />
    </a>

    <button v-else :class="addClasses" aria-label="Navbar link">
        <slot />
    </button>
</template>
