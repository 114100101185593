import { CachedCartItem } from '@/interfaces/CartItem'

export default (cartItems: CachedCartItem[]): boolean => {
    if (!Array.isArray(cartItems))
        return true

    for (const item of cartItems) {
        if (!item.specialistId || isNaN(item.specialistId)) {
            return true
        }
    }

    return false
}
