<script setup lang="ts">
const commonCloudClasses = 'absolute drop-shadow-md opacity-90 animate-cloud-float'
</script>

<template>
    <div class="absolute w-full bottom-2">
        <img
            src="/storage/cloud-2.webp"
            :class="commonCloudClasses"
            class="bottom-[-20px] left-[-7px] h-[90px] contrast-200"
            aria-hidden="true"
            style="animation-delay: 600ms; animation-duration: 4s"
            width="366"
            height="90"
        />
        <img
            src="/storage/cloud-1.webp"
            :class="commonCloudClasses"
            class="bottom-[-13px] left-[350px] h-[80px]"
            aria-hidden="true"
            style="animation-delay: 400ms"
            width="262"
            height="80"
        />
        <img
            src="/storage/cloud-2.webp"
            :class="commonCloudClasses"
            class="bottom-[-40px] left-[500px] h-[90px]"
            aria-hidden="true"
            style="animation-duration: 3s"
            width="366"
            height="90"
        />
        <img
            src="/storage/cloud-1.webp"
            :class="commonCloudClasses"
            class="bottom-[-14px] right-[510px] h-[80px] contrast-200"
            aria-hidden="true"
            style="animation-delay: 800ms; animation-duration: 6s"
            width="262"
            height="80"
        />
        <img
            src="/storage/cloud-2.webp"
            :class="commonCloudClasses"
            class="bottom-[-54px] right-[210px] h-[90px]"
            aria-hidden="true"
            style="animation-delay: 500ms"
            width="366"
            height="90"
        />
        <img
            src="/storage/cloud-1.webp"
            :class="commonCloudClasses"
            class="right-[-7px] bottom-[-14px] h-[80px] contrast-200"
            aria-hidden="true"
            style="animation-delay: 1000ms; animation-duration: 7s"
            width="262"
            height="80"
        />
    </div>
</template>


<style>
@keyframes cloud-float {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(-13px, -5px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.animate-cloud-float {
    animation: cloud-float 5s ease-in-out infinite;
}
</style>
