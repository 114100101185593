export default (itemsSelector: string): void => {
    const items = document.querySelectorAll<Element>(itemsSelector)
    let scaleIndex = 0
    let unscaleIndex = 0

    const scaleInterval = setInterval(() => {
        if (!items[scaleIndex]) {
            clearInterval(scaleInterval)
            return
        }

        items[scaleIndex].classList.add('scale-150')
        scaleIndex++

        const unscaleInterval = setInterval(() => {
            if (!items[unscaleIndex]) {
                clearInterval(unscaleInterval)
                return
            }

            items[unscaleIndex].classList.remove('scale-150')
            unscaleIndex++
        }, 200)
    }, 100)
}
