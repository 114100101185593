import appConfig from '@/appConfig'

export default class {
    public static getCoupons() {
        const coupon = localStorage.getItem(appConfig.storageKeys.orderCoupons)
        return coupon ? JSON.parse(coupon) : []
    }

    public static getCouponByServiceId(specialistId: number, serviceId: number) {
        const coupon_json = localStorage.getItem(appConfig.storageKeys.orderCoupons)
        const coupon = coupon_json ? JSON.parse(coupon_json) : [];
        let result = undefined;

        if (coupon.length !== 0) {
            coupon.forEach(function (value: any) {
                if (value.serviceId === serviceId && value.specialistId === specialistId) {
                    result = value.coupon
                }
            });
        }

        return result
    }

    public static update(specialistId: number, serviceId: number, coupon: string | null): void {
        let coupons = this.getCoupons();

        const index = coupons.findIndex(
            (item: { specialistId: number, serviceId: number, coupon: string | null }) =>
                item.serviceId === serviceId && item.specialistId === specialistId
        );

        if (index !== -1) {
            coupons[index].coupon = coupon;
        } else {
            coupons.push({'specialistId': specialistId, 'serviceId': serviceId, 'coupon': coupon});
        }

        this.save(coupons);
    }

    public static removeCoupon(specialistId: number, serviceId: number): void {
        let coupons = this.getCoupons();

        if (coupons.length === 0) {
            return
        }

        coupons = coupons.filter((item: { specialistId: number, serviceId: number, coupon: string | null }) => {
            return item.specialistId !== specialistId || item.serviceId !== serviceId;
        });

        this.save(coupons)
    }

    private static save(coupons: any): void {
        localStorage.setItem(appConfig.storageKeys.orderCoupons, JSON.stringify(coupons))
    }
}
