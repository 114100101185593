<script setup lang="ts">
import type SpecialistType from '@/interfaces/Specialist'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import VueHorizontal from 'vue-horizontal'
import VerticalSpecialistCard from '../Specialist/VerticalSpecialistCard.vue'
import linkTo from '@/modules/linkTo'

const specialists = ref<SpecialistType[]>([])
const loading = ref(true)

onMounted(() => fetchSpecialists())

function fetchSpecialists(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/specialists/popular',
    }

    makeRequest<SpecialistType[]>(config)
        .then(data => specialists.value = data)
        .finally(() => loading.value = false)
}
</script>

<template>
    <div>
        <vue-horizontal>
            <div
                v-if="loading"
                v-for="i in 5"
                key="i"
                class="w-[293px] h-[606px] bg-page flex flex-col justify-between p-5 rounded-lg shadow-md mr-5 animate-pulse"
            >
            </div>

            <div
                v-else
                v-for="spec in specialists"
                :key="spec.id"
                class="w-full max-w-[293px] mr-5"
            >
                <vertical-specialist-card
                    :link="linkTo(`specialists/${spec.slug}`)"
                    :specialist="spec"
                    :price="spec.price"
                />
            </div>
        </vue-horizontal>
    </div>
</template>
