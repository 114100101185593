<script setup lang="ts">
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type ReviewType from '@/interfaces/Review'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import ReviewButton from '@/components/Home/ReviewButton.vue'
import Review from '@/components/Home/Review.vue'
import Spinner from '@/components/Spinner.vue'

const reviews = ref<ReviewType[]>([])
const reviewsOffset = ref<number>(0)
const loading = ref<boolean>(true)

onMounted(() => {
    fetchReviews()
})

function fetchReviews(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/reviews',
        params: { offset: reviewsOffset.value },
    }

    makeRequest<ReviewType[]>(config)
        .then(data => reviews.value = data)
        .finally(() => loading.value = false)
}

function refetchReviews(increment: boolean): void {
    if (increment) {
        reviewsOffset.value += 3
    } else {
        reviewsOffset.value -= 3
    }

    if (reviewsOffset.value < 0) {
        reviewsOffset.value = 0
    }

    fetchReviews()
}
</script>

<template>
    <div>
        <spinner v-if="loading" />

        <div v-else class="flex flex-col-reverse sm:flex-row gap-5">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <review
                    v-for="review in reviews"
                    :key="review.title + review.author_name"
                    :review="review"
                />
            </div>

            <div class="text-lg flex flex-row sm:flex-col gap-4 justify-center">
                <review-button direction="left" @click="() => refetchReviews(false)" />
                <review-button direction="right" @click="() => refetchReviews(true)" />
            </div>
        </div>
    </div>
</template>
