<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type Service from '@/interfaces/Service'
import useDropdown from '@/composables/navbar/useDropdown'
import NavIcon from '@/components/Navbar/Menu/NavIcon.vue'
import MobileDropdown from './MobileDropdown.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import MobileNavLink from '@/components/Navbar/Menu/Mobile/MobileNavLink.vue'
import CategoryLinkIcon from '@/components/Navbar/CategoryLinkIcon.vue'
import useNavLinkTitle from '@/composables/navbar/useNavLinkTitle'

interface Props {
    category?: Category
    service?: Service
    href?: string
}

const { category, service, href } = defineProps<Props>()
const { dropdownIsVisible, toggleDropdown } = useDropdown()
const { linkTitle } = useNavLinkTitle(service, category)
</script>

<template>
    <mobile-nav-link
        aria-haspopup="menu"
        :href="href ? href : null"
        @click="toggleDropdown(!dropdownIsVisible)"
    >
        <div class="text-main-dark font-bold flex items-center justify-between gap-x-4 w-full text-left">
            <div class="flex items-center gap-3">
                <category-link-icon :category="category" />
                <span class="leading-6 uppercase">{{ linkTitle }}</span>
            </div>

            <nav-icon
                v-if="category"
                iconName="lni-plus"
                class="ml-3 text-xs font-black"
            />
        </div>
    </mobile-nav-link>

    <slide-bottom-transition v-if="dropdownIsVisible && category">
        <mobile-dropdown
            v-if="category.categories && category.categories.length > 0"
            :categories="category.categories"
            :key="category.id"
            :parent="category"
            @pressBackButton="dropdownIsVisible = false"
        />

        <mobile-dropdown
            v-if="category.services && category.services.length > 0"
            :services="category.services"
            :key="category.id"
            :parent="category"
            @pressBackButton="dropdownIsVisible = false"
        />
    </slide-bottom-transition>
</template>
