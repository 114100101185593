<script setup lang="ts">
import type Service from '@/interfaces/Service'
import OutlineButton from '@/components/Buttons/OutlineButton.vue'
import linkTo from '@/modules/linkTo'
import { computed } from 'vue'

interface Props {
    service: Service
    specialistSlug?: string
    price: number | null
}

const { service, specialistSlug, price } = defineProps<Props>()

const link = computed(() => {
    return specialistSlug
        ? linkTo(`services/${service.slug}?specialist=${specialistSlug}`)
        : linkTo(`services/${service.slug}`)
})
</script>

<template>
    <div class="flex flex-col justify-between bg-page rounded-md shadow-md relative min-h-auto md:min-h-[300px]">
        <!-- Top -->
        <div class="border-b py-2 px-4">
            <a
                :href="linkTo(`categories/${service.category.slug}`)"
                v-if="service.category"
                class="text-main-dark hover:text-black transition-colors text-xs font-bold uppercase inline-block leading-2"
            >
                {{ service.category.title }}
            </a>
        </div>

        <!-- Title -->
        <h2 class="leading-6 mt-4 px-4 text-lg">
            {{ service.title }}
        </h2>

        <div>
            <div class="flex justify-between items-center mt-2">
                <span class="flex flex-col px-4">
                    <b class="block my-2 text-lg text-main-dark">
                        ~ {{ service.time }} {{ $trans.get('common.mins') }}
                    </b>

                    <small class="-mt-4 text-gray-500">
                        {{ $trans.get('common.duration') }}
                    </small>
                </span>

                <span class="flex flex-col text-right px-4">
                    <b v-if="price === 0" class="block text-right my-2 text-lg">
                        {{ $trans.get('common.free') }}
                    </b>

                    <b v-if="price && price > 0" class="block text-right my-2 text-lg">
                        {{ $trans.get('common.from') }} {{ price }} €
                    </b>

                    <small v-if="price && price > 0" class="-mt-4 text-gray-500">
                        {{ $trans.get('common.inc_vat') }}
                    </small>
                </span>
            </div>

            <div class="px-4 pb-4">
                <outline-button :to="link">
                    {{ $trans.get('common.choose') }}
                </outline-button>
            </div>
        </div>
    </div>
</template>
