import Cookies from 'js-cookie'
import appConfig from '@/appConfig'

export default () => {
    function changeLanguageInUrl(lang: string): string {
        let path = location.pathname
        let langFromUrl = path.substring(1, 3)

        const languages = LanguagesLinks.map(link => link.slug)

        if (languages.includes(langFromUrl)) {
            const replacement = lang === DefaultLocale ? '' : `/${lang}`
            const newPath = path.replace(`/${langFromUrl}`, replacement)

            return newPath === '' ? '/' : newPath
        }

        return lang === DefaultLocale ? path : `/${lang}${path}`
    }

    function switchLanguage(lang: string): void {
        Cookies.set(appConfig.cookieKeys.lang, lang, { expires: 365 })
        location.href = changeLanguageInUrl(lang)
    }

    return {
        switchLanguage,
    }
}
