<script setup lang="ts">
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import CartItem, {DisabledDate} from '@/interfaces/CartItem'
import {DatePicker} from 'v-calendar'
import OrderCache from '@/modules/Cache/OrderCache'
import {onMounted, ref, watch} from 'vue'
import makeRequest from '@/modules/makeRequest'
import moment from 'moment'
import BlockItemDate from "@/interfaces/CartItemBlockDate";
import OrderType from "@/interfaces/Order/OrderType";
import {Durations} from '@/interfaces/CartItem'

type Props = {
    blockItemDate: BlockItemDate[]
    cartItem: CartItem
    orderType: OrderType
    durations: Durations
}

const props = defineProps<Props>()
const validHours = ref<number[]>([])
const validMinutes = ref<number[]>([])
const disabledDates = ref<DisabledDate[]>(props.cartItem.appointment.disabledDates)
const loadingDisabledDates = ref<boolean>(true)
const rules = ref({
    hours: validHours,
    minutes: validMinutes,
});
const blockItemDate = ref(props.blockItemDate);
const durations = ref<Durations>(props.durations)

watch(props.cartItem, () => {
    updateCacheAndWorkingTime()

    const date = [
        {
            month: props.cartItem.appointment.date.getMonth() + 1,
            year: props.cartItem.appointment.date.getFullYear()
        },
    ];
    updateDisabledDates(date)
})

onMounted(() => {
    updateCacheAndWorkingTime()
})

function updateCacheAndWorkingTime(): void {
    OrderCache.updateAppointment(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.appointment.date)
    changeWorkingHours(props.cartItem.appointment.date)
    changeWorkingMinutes(props.cartItem.appointment.date)
}

function updateDisabledDates(data: { month: any, year: any }[]): void {
    loadingDisabledDates.value = true

    let other_orders = findBlockItemDateBySpecialistAndNotServiceId(
        props.blockItemDate,
        props.cartItem.specialistId,
        props.cartItem.service.id
    );

    const conf: MakeRequestConfig = {
        url: '/api/disabled-dates',
        params: {
            year: data[0].year,
            month: data[0].month,
            specialist_id: props.cartItem.specialistId,
            service_id: props.cartItem.service.id,
            duration: durations.value.selected,
            other_orders: other_orders
        },
    }

    makeRequest<DisabledDate[]>(conf)
        .then(data => {
            disabledDates.value = data
        })
        .finally(() => loadingDisabledDates.value = false)
}

function changeWorkingHours(date: Date): void {
    let other_orders = findBlockItemDateBySpecialistAndNotServiceId(
        props.blockItemDate,
        props.cartItem.specialistId,
        props.cartItem.service.id
    );

    const conf: MakeRequestConfig = {
        url: '/api/valid-hours',
        params: {
            date: date,
            specialist_id: props.cartItem.specialistId,
            service_id: props.cartItem.service.id,
            duration: durations.value.selected,
            other_orders: other_orders
        },
    }

    makeRequest<number[]>(conf)
        .then(data => validHours.value = data)
}

function changeWorkingMinutes(date: Date): void {
    let other_orders = findBlockItemDateBySpecialistAndNotServiceId(
        props.blockItemDate,
        props.cartItem.specialistId,
        props.cartItem.service.id
    );

    const conf: MakeRequestConfig = {
        url: '/api/valid-minutes',
        params: {
            date: date,
            specialist_id: props.cartItem.specialistId,
            service_id: props.cartItem.service.id,
            duration: durations.value.selected,
            other_orders: other_orders
        },
    }

    makeRequest<number[]>(conf)
        .then(data => validMinutes.value = data)
}

function findBlockItemDateBySpecialistAndNotServiceId(
    blockItemDate: BlockItemDate[],
    specialistId: number,
    serviceId: number
): BlockItemDate[] {
    return blockItemDate.filter(item => item.specialistId === specialistId && item.serviceId !== serviceId)
        .map(item => ({ ...item }));
}
</script>

<template>
    <date-picker
        v-if="props.cartItem.appointment.workingHours"
        v-model="props.cartItem.appointment.date"
        :disabled-dates="disabledDates"
        timezone="UTC"
        mode="dateTime"
        @did-move="updateDisabledDates"
        color="teal"
        :rules="rules"
        :is-required="true"
        :min-date="moment().add(1, 'days').toDate()"
        :max-date="moment().add(4, 'months').toDate()"
        :locale="$locale"
        is24hr
        :class="{ 'loading': loadingDisabledDates }"
    >
        <template v-slot="{ inputValue, inputEvents }">
            <input
                id="consultation_date"
                class="bg-white border px-2 py-1 rounded w-100"
                :value="inputValue"
                v-on="inputEvents"
            />
        </template>
    </date-picker>
</template>
