<script setup lang="ts">
import type Filter from '@/interfaces/Filter/Filter'
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref, watch } from 'vue'
import { Listbox } from '@headlessui/vue'
import listenEvent from '@/modules/listenEvent'
import makeRequest from '@/modules/makeRequest'
import appConfig from '@/appConfig'
import FilterButton from './Elements/FilterButton.vue'
import FilterOption from './Elements/FilterOption.vue'
import FilterOptions from './Elements/FilterOptions.vue'
import FilterNumber from './Elements/FilterNumber.vue'

interface Emits {
    (e: 'changedItems', ids: number[]): void
}

const emit = defineEmits<Emits>()

const selectedIds = ref<number[]>([])
const selectedItems = ref<Category[]>([])
const categories = ref<Category[]>([])
const loading = ref<boolean>(true)
const allowWatch = ref(false)

onMounted(fetchItems)

watch(selectedItems, () => {
    if (allowWatch.value) {
        let ids: number[] = []
        selectedItems.value.forEach(item => ids.push(item.id))
        emit('changedItems', ids)
    }
})

function fetchItems(): void {
    const config: MakeRequestConfig = {
        url: '/api/filters/category',
    }

    makeRequest<Category[]>(config)
        .then(data => setItems(data))
        .finally(() => loading.value = false)
}

function setItems(responseCategories: Category[]): void {
    categories.value = responseCategories
    selectedItems.value = responseCategories.filter(cat => selectedIds.value.includes(cat.id))
    setTimeout(() => allowWatch.value = true, 500)
}

function clearFilter(): void {
    selectedItems.value = []
    selectedIds.value = []
    emit('changedItems', [])
}

listenEvent<Filter>(appConfig.events.activeFilters, filter => {
    selectedIds.value = filter.categories
})
</script>

<template>
    <div class="inline-block relative">
        <listbox v-model="selectedItems" multiple>
            <filter-button
                :disabled="selectedItems.length === 0"
                :title="$trans.get('common.categories')"
                @clearFilter="clearFilter"
            >
                <filter-number v-if="selectedItems.length > 0">
                    {{ selectedItems.length }}
                </filter-number>
            </filter-button>

            <filter-options :loading="loading">
                <filter-option
                    v-if="categories"
                    v-for="category in categories"
                    :key="category.id"
                    :value="category"
                >
                    <div class="flex items-center gap-x-2">
                        <span class="text-sm">{{ category.title }}</span>
                    </div>
                </filter-option>
            </filter-options>
        </listbox>
    </div>
</template>
