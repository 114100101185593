import { ref } from 'vue'

export default () => {
    const dropdownIsVisible = ref<boolean>(false)

    function toggleDropdown(state: boolean): void {
        dropdownIsVisible.value = state
    }

    return {
        dropdownIsVisible,
        toggleDropdown,
    }
}
