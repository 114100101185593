<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type Service from '@/interfaces/Service'
import MobileDropdownLink from './MobileDropdownLink.vue'
import MobileDropdownShowMore from './MobileDropdownShowMore.vue'

interface Props {
    categories?: Category[]
    services?: Service[]
    parent?: Category
}

interface Emits {
    (e: 'pressBackButton'): void
}

const emit = defineEmits<Emits>()
const { categories, services, parent } = defineProps<Props>()
const maxServices = 6

if (services?.length) {
    if (services.length > maxServices) {
        services.splice(maxServices);
        services.short_list = true;
    }
}
</script>

<template>
    <aside class="min-h-screen left-0 right-0 bg-page z-20 fixed top-[var(--page-top-sm-mobile)]">
        <div class="flex flex-col">
            <button
                type="button"
                aria-label="Go back"
                @click="emit('pressBackButton')"
                class="text-center py-5"
            >
                <div class="flex items-center justify-center gap-2 text-main font-bold">
                    <i class="lni lni-chevron-left font-black text-xs" aria-hidden="true"></i>
                    <span class="uppercase text-[1.3em]">{{ $trans.get('common.back') }}</span>
                </div>
            </button>

            <div v-if="parent" class="pb-2 px-2 text-center">
                <a
                    :href="`/categories/${parent.slug}`"
                    class="!text-main text-[1.5em] leading-5"
                >
                    {{ parent.title }}
                </a>
            </div>

            <mobile-dropdown-link
                v-if="categories"
                v-for="category in categories"
                :key="category.id"
                :category="category"
            />

            <mobile-dropdown-link
                v-if="services"
                v-for="service in services"
                :key="service.id"
                :href="`/services/${service.slug}`"
                :service="service"
            />
            <mobile-dropdown-show-more
                v-if="services && services.short_list === true"
                :parent="parent"
            />
        </div>
    </aside>
</template>
