<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { computed, onMounted, ref } from 'vue'
import appConfig from '@/appConfig'
import listenEvent from '@/modules/listenEvent'
import NavLink from '@/components/Navbar/Menu/NavLink.vue'
import SlideBottomTransition from '@/components/Transition/SlideBottomTransition.vue'
import userButtonLinks from '@/modules/dataObjects/userButtonLinks'
import specButtonLinks from '@/modules/dataObjects/specButtonLinks'
import getUserName from '@/modules/getUserName'

type Props = {
    first: string | null
    last: string | null
    isClient: boolean
}

const { first, last, isClient } = defineProps<Props>()
const userName = ref(getUserName(first, last, 18))

onMounted(() => {
    listenEvent<{ first: string, last: string }>(appConfig.events.userIsUpdated, name => {
        userName.value = getUserName(name.first, name.last, 18)
    })
})

const links = computed(() => isClient ? userButtonLinks : specButtonLinks)

function linkIsActive(href: string): boolean {
    if (href === '#') {
        return false
    }

    return window.location.href.includes(href)
}
</script>

<template>
    <popover v-slot="{ open }" class="relative">
        <popover-button class="h-full focus:outline-none">
            <nav-link
                classes="bg-main !text-white hover:!bg-main-hover"
                class="h-full"
            >
                <div class="flex gap-x-3 items-center justify-center w-full">
                    <i
                        class="lni text-md font-bold"
                        :class="open ? 'lni-close' : 'lni-chevron-down'"
                        aria-hidden="true"
                    ></i>

                    <span class="whitespace-nowrap">{{ userName }}</span>
                </div>
            </nav-link>
        </popover-button>

        <slide-bottom-transition>
            <popover-panel
                class="absolute right-0 z-10 mt-3 w-screen max-w-md px-4"
            >
                <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                >
                    <div class="relative flex flex-col gap-8 bg-page p-7">
                        <a
                            v-for="link in links"
                            :key="link.name"
                            :href="link.href"
                            class="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-main-light focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            :class="{ 'bg-main-extra-light': linkIsActive(link.href) }"
                            @click="link.action ? link.action() : null"
                        >
                            <div class="bg-main rounded-lg flex h-10 w-10 shrink-0 items-center justify-center text-page sm:h-12 sm:w-12">
                                <i class="lni text-xl" :class="link.icon" aria-hidden="true"></i>
                            </div>

                            <div class="ml-4">
                                <p class="text-sm font-medium text-gray-900">
                                    {{ link.name }}
                                </p>

                                <p class="font-normal text-sm text-gray-500">
                                    {{ link.description }}
                                </p>
                            </div>
                        </a>
                    </div>
                </div>
            </popover-panel>
        </slide-bottom-transition>
    </popover>
</template>
