<script setup lang="ts">
import type Category from '@/interfaces/Category'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import Spinner from '@/components/Spinner.vue'
import CategoryCard from '@/components/Category/CategoryCard.vue'
import Filters from '@/components/Filter/Filters.vue'
import NotFound from '@/components/NotFound.vue'

const categories = ref<Category[]>([])
const loading = ref(true)

onMounted(fetchCategories)

function filtersChanged(): void {
    fetchCategories()
}

function fetchCategories(): void {
    loading.value = true

    const config: MakeRequestConfig = {
        url: '/api/categories' + window.location.search,
    }

    makeRequest<Category[]>(config)
        .then(data => categories.value = data)
        .finally(() => loading.value = false)
}
</script>

<template>
    <div class="pb-7 pt-3 min-h-[500px]">
        <filters
            :title="$trans.get('common.found_categories')"
            :count="categories.length"
            :filters="['query']"
            @filtersChanged="filtersChanged"
        />

        <div class="container">
            <div v-if="loading" class="text-center pt-5">
                <spinner class="mx-auto" />
            </div>

            <div v-else-if="!loading && categories.length === 0" class="text-center pt-5">
                <not-found class="mx-auto">
                    {{ $trans.get('common.services_not_found') }}
                </not-found>
            </div>

            <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
                <category-card
                    v-for="category in categories"
                    :key="category.id"
                    :category="category"
                />
            </div>
        </div>
    </div>
</template>
