<script setup lang="ts">
import { computed, ref } from 'vue'

type Props = {
    fullContent: string | null
    maxLength: number
}

const props = defineProps<Props>()
const showMore = ref<boolean>(false)

const buttonTitle = computed(() => {
    if (showMore.value) {
        return Lang.get('common.hide')
    }

    return Lang.get('common.show_more')
})

const content = computed(() => {
    if (!props.fullContent) {
        return ''
    }

    if (showMore.value) {
        return props.fullContent.trim()
    }

    const dots = props.fullContent.length > props.maxLength ? ' ...' : ''

    return props.fullContent.trim().substring(0, props.maxLength) + dots
})
</script>

<template>
    <p class="whitespace-pre-line">{{ content }}</p>

    <div v-if="props.fullContent && props.fullContent.length > props.maxLength">
        <button
            type="button"
            aria-label="Show more content"
            class="font-bold text-main"
            @click="showMore = !showMore"
        >
            <span>{{ buttonTitle }}</span>

            <i
                class="lni font-black text-xs ml-2"
                :class="showMore ? 'lni-chevron-right' : 'lni-chevron-down'"
            ></i>
        </button>
    </div>
</template>

