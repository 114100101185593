<script setup lang="ts">
import type Order from '@/interfaces/Order/Order'
import OrderTitle from '@/components/Auth/User/Order/OrderTitle.vue'
import OrderService from '@/components/Auth/User/Order/OrderService.vue'
import ListItems from '@/components/Auth/Spec/Order/ListItems.vue'
import { computed } from 'vue'
import Rate from '@/components/Rate.vue'
import Tip from '@/components/Tip.vue'

type Props = {
    order: Order
}

const { order } = defineProps<Props>()

const orderTitle = computed(() => {
    return `${Lang.get('common.order')} ${order.name} (${Lang.get(`common.${order.type}`)})`
})
</script>

<template>
    <div class="relative">
        <article class="grid grid-cols-1 lg:grid-cols-2 gap-6 bg-page rounded-md p-6 shadow-md">
            <div>
                <order-title
                    :title="orderTitle"
                    :intro="$trans.get('common.spec_order_intro')"
                />

                <ul class="flex flex-col gap-4">
                    <order-service
                        v-if="order.services"
                        v-for="service in order.services"
                        :key="service.id"
                        :service="service"
                        :order-type="order.type"
                        :order-coupon="order.coupon"
                    />
                </ul>
            </div>

            <div class="flex flex-col justify-between">
                <list-items :order="order" />

                <div class="flex justify-between items-end mt-4 pt-4 border-t border-border">
                    <div class="flex flex-col gap-1">
                        <div class="flex flex-col md:flex-row gap-1 text-gray-500">
                            <div>
                                <div class="lni lni-calendar mr-2"></div>
                                <span>{{ $trans.get('common.creation_date') }}:</span>
                            </div>
                            <b>{{ order.pretty_created_at }}</b>
                        </div>

                        <div class="flex flex-col md:flex-row gap-1 text-gray-500">
                            <div>
                                <div class="lni lni-user mr-2"></div>
                                <span>{{ $trans.get('common.client') }}:</span>
                            </div>
                            <b v-if="order.user">
                                {{ order.user.first }} {{ order.user.last }}
                            </b>
                        </div>
                    </div>

                    <tip v-if="order.rate" :content="$trans.get('common.client_rate_for_order')">
                        <rate :rate="order.rate" />
                    </tip>
                </div>
            </div>
        </article>
    </div>
</template>
