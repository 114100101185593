import type NavbarLink from '../../interfaces/NavbarLink'
import linkTo from '@/modules/linkTo'

export default (): NavbarLink[] => {
    return [
        {
            title: Lang.get('common.services'),
            href: linkTo('services'),
            isDropdown: true,
        },
        {
            title: Lang.get('common.specialists'),
            href: linkTo('specialists'),
            isDropdown: false,
        },
        {
            title: Lang.get('cooperation.cooperation'),
            href: linkTo('cooperation'),
            isDropdown: false,
        },
    ]
}
