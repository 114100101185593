<script setup lang="ts">
import type Specialist from '@/interfaces/Specialist'
import type Service from '@/interfaces/Service'
import type Category from '@/interfaces/Category'
import useSearch from '@/composables/useSearch'

interface Props {
    heading: string
    items: Specialist[] | Service[] | Category[]
    linkUrl: string
}

const { heading, items, linkUrl } = defineProps<Props>()
const { saveItemToHistory } = useSearch()

function getName(item: Specialist | Service | Category): string {
    return (item as Specialist).name || (item as Service | Category).title
}
</script>

<template>
    <div class="flex-1">
        <h2 class="mx-2 mb-4 text-md text-main-dark font-bold">{{ heading }}</h2>

        <ul class="text-xs leading-5 divide-y divide-border">
            <li v-for="item in items" :key="item.id">
                <a
                    :href="linkUrl.replace('{slug}', item.slug)"
                    class="flex justify-between gap-x-3 items-center hover:bg-page-hover transition-colors rounded-md py-2 px-3"
                    @click="() => saveItemToHistory(getName(item), linkUrl.replace('{slug}', item.slug))"
                >
                    <span>{{ getName(item) }}</span>

                    <span
                        v-if="(item as Service).pretty_price"
                        class="flex-none font-bold text-main-dark bg-main-extra-light px-2 py-1 rounded-md"
                    >
                        {{ (item as Service).pretty_price }}
                    </span>
                </a>
            </li>
        </ul>
    </div>
</template>

