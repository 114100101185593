<script setup lang="ts">
import type Service from '@/interfaces/Service'
import type Specialist from '@/interfaces/Specialist'
import type CouponEvent from '@/interfaces/CouponEvent'
import DialogPanel from '@/components/Dialog/DialogPanel.vue'
import DialogTitle from '@/components/Dialog/DialogTitle.vue'
import DialogWrapper from '@/components/Dialog/DialogWrapper.vue'
import {onMounted, Ref, ref, watch} from "vue";
import listenEvent from "@/modules/listenEvent";
import appConfig from "@/appConfig";
import MakeRequestConfig from "@/interfaces/MakeRequestConfig";
import makeRequest from "@/modules/makeRequest";
import CouponCache from "@/modules/Cache/CouponCache";
import OrderCache from "@/modules/Cache/OrderCache";
import OkButton from "@/components/Buttons/OkButton.vue";
import CancelButton from "@/components/Buttons/CancelButton.vue";
import showToast from "@/modules/showToast";
import dispatchEvent from "@/modules/dispatchEvent";

type Props = {
    service: Service
    specialist: Specialist | null
}

const {service, specialist} = defineProps<Props>()
const inputIconStatus = ref<string>()
const inputStyles = 'bg-white border px-2 py-1 rounded'
const couponPopupState = ref<boolean>(false)
const coupon = ref<string>()

watch(() => coupon.value, () => {
    if (coupon.value !== null && coupon.value !== undefined) {
        if (coupon.value.length !== 5) {
            inputIconStatus.value = '';
        }

        if (coupon.value.length === 5) {
            inputIconStatus.value = 'input-icon-coupon-loading';

            const conf: MakeRequestConfig = {
                url: '/api/coupon-check',
                params: {
                    code: coupon.value,
                    service_id: service.id,
                },
            }

            makeRequest<object>(conf)
                .then((data: object) => {
                    if ('coupon_status' in data) {
                        if (data['coupon_status'] === true) {
                            inputIconStatus.value = 'input-icon-coupon-correct';

                            if (specialist && coupon.value) {
                                CouponCache.update(specialist.id, service.id, coupon.value);
                            }
                        } else {
                            inputIconStatus.value = 'input-icon-coupon-wrong';
                        }
                    }
                })
                .finally(() => {
                    if (inputIconStatus.value === 'input-icon-coupon-loading') {
                        inputIconStatus.value = ''
                    }
                })
        }
    }
})

listenEvent<CouponEvent>(appConfig.events.showCouponPopup, couponEvent => {
    if (couponEvent.specialistId === specialist?.id && couponEvent.serviceId === service.id) {
        couponPopupState.value = couponEvent.state
    }
})

function setCouponPopupState(value: boolean) {
    couponPopupState.value = value;
}

function addServiceToOrderIfCouponSet(): void {
    if (!specialist) {
        setCouponPopupState(false)
        showToast({text: Lang.get('common.error_specialist_not_found'), success: false})
        return
    }

    if (coupon.value && coupon.value === CouponCache.getCouponByServiceId(specialist.id, service.id)) {
        setCouponPopupState(false)
        OrderCache.add(specialist.id, service.id)
        showToast({text: Lang.get('common.service_added')})
        dispatchEvent(appConfig.events.refreshCart)
    } else {
        CouponCache.removeCoupon(specialist.id, service.id)
        setCouponPopupState(false)
        showToast({text: Lang.get('common.no_coupon_entered'), success: false})
    }
}
</script>

<template>
    <dialog-wrapper @close="setCouponPopupState(false)" :open="couponPopupState">
        <div class="fixed inset-0 flex items-center justify-center p-4">
            <div class="flex min-h-full items-center justify-center">
                <dialog-panel @closeModal="setCouponPopupState(false)">
                    <dialog-title class="mb-5">{{ $trans.get('common.coupon_popup_title') }}</dialog-title>

                    <div class="text-md col-span-1 md:col-span-2 border border-yellow-200 rounded-lg p-3 bg-yellow-50">
                        {{ $trans.get('common.consultations_coupon_only_text') }}.
                    </div>

                    <h3 class="text-xl my-3">{{ $trans.get('common.coupon') }}</h3>

                    <div class="input-icon-block">
                        <input
                            :class="inputStyles"
                            type="text"
                            id="coupon"
                            v-model="coupon"
                        />

                        <span
                            :class="inputIconStatus"
                            class="input-icon"
                        ></span>
                    </div>

                    <ok-button
                        class="mr-3 mt-5"
                        aria-label="OK"
                        @addServiceToOrderIfCouponSet="addServiceToOrderIfCouponSet"
                    >
                    </ok-button>

                    <cancel-button
                        class="mr-3 mt-5"
                        style="background-color: red"
                        aria-label="cancel"
                        @click="setCouponPopupState(false)"
                    >
                    </cancel-button>
                </dialog-panel>
            </div>
        </div>
    </dialog-wrapper>
</template>
