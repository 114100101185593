export default (categoryId: number): string | null => {
    switch (categoryId) {
        case 1:
            return 'lni-users'
        case 2:
            return 'lni-plane'
        case 3:
            return 'lni-briefcase'
        default:
            return null
    }
}
