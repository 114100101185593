import type ContactUsTimer from '@/interfaces/ContactUsTimer'
import appConfig from '@/appConfig'

export default class {
    private readonly cacheKey: string

    public constructor(private timerSeconds: number) {
        this.cacheKey = appConfig.storageKeys.contactUsTimer
    }

    public get(): ContactUsTimer {
        const cache = localStorage.getItem(this.cacheKey)

        if (cache) {
            return JSON.parse(cache)
        }

        const defaultTimer = this.createDefaultTimer()

        this.set(defaultTimer)

        return defaultTimer
    }

    public set(timer: ContactUsTimer): void {
        localStorage.setItem(this.cacheKey, JSON.stringify(timer))
    }

    private createDefaultTimer(): ContactUsTimer {
        return {
            secondsLeft: this.timerSeconds,
            toastWasDisplayed: false,
        }
    }
}
