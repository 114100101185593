<script setup lang="ts">
interface Props {
    title: string
    icon?: string
    to?: string
}

type Emits = {
    (e: 'clicked'): void
}

const emit = defineEmits<Emits>()

const { title, to, icon } = defineProps<Props>()
const styles = 'inline-flex justify-center items-center py-2 lg:py-3 px-7 border-2 border-main-dark text-main-dark font-bold rounded-full uppercase transition-colors mx-auto hover:border-decor hover:text-decor'
</script>

<template>
    <a v-if="to" :href="to" :class="styles" @click="emit('clicked')">
        <i v-if="icon" :class="`lni lni-${icon} mr-4 text-2xl`"></i>
        <span class="text-sm">{{ title }}</span>
        <slot name="end" />
    </a>

    <button v-else type="button" :class="styles" @click="emit('clicked')">
        <i v-if="icon" :class="`lni lni-${icon} mr-4 text-2xl`"></i>
        <span class="text-sm">{{ title }}</span>
        <slot name="end" />
    </button>
</template>
