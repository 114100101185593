<template>
    <transition
        enter-active-class="transition duration-200"
        enter-from-class="transform -translate-x-2 opacity-0"
        enter-to-class="transform translate-x-0 opacity-100"
        leave-active-class="opacity-0"
        appear
    >
        <slot />
    </transition>
</template>
