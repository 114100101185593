import appConfig from '@/appConfig'
import showToast from '@/modules/showToast'
import ContactUsToastCache from '@/modules/Cache/ContactUsToastCache'

export default class {
    private readonly cache: ContactUsToastCache

    public constructor(private timerSeconds: number) {
        this.cache = new ContactUsToastCache(timerSeconds)
    }

    public displayAfterSeconds(): void {
        if (this.cache.get().toastWasDisplayed)
            return

        window.setInterval(() => this.decrementTimer(), 1000)
    }

    private getSeconds(): number {
        const timer = localStorage.getItem(appConfig.storageKeys.contactUsTimer)

        if (!timer)
            return this.timerSeconds

        return Number(timer)
    }

    private decrementTimer(): void {
        const timer = this.cache.get()

        if (timer.secondsLeft > 0) {
            timer.secondsLeft--
            this.cache.set(timer)
            return
        }

        if (timer.toastWasDisplayed)
            return

        showToast({
            text: Lang.get('common.contact_us_if_have_question'),
            duration: 180000,
        })

        timer.toastWasDisplayed = true

        this.cache.set(timer)
    }
}
