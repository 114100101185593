<script setup lang="ts">
import type OrderForm from '@/interfaces/Order/OrderForm'
import type User from '@/interfaces/User'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import type FormOrderTypeOption from '@/interfaces/Order/FormOrderTypeOption'
import type FormOrderVatOption from '@/interfaces/Order/FormOrderVatOption'
import {onMounted, ref, watch} from 'vue'
import makeRequest from '@/modules/makeRequest'
import confirmModal from '@/modules/confirmModal'
import appConfig from '@/appConfig'
import FormLabel from '@/components/Form/FormLabel.vue'
import Tip from '@/components/Tip.vue'
import OrderCache from "@/modules/Cache/OrderCache";
import {DisabledDate} from "@/interfaces/CartItem";

type Props = {
    form: OrderForm
    user: User | null
}

const { form, user } = defineProps<Props>()
const inputStyles = 'border rounded-lg px-4 py-2 focus:outline-main w-full max-w-auto'

const showVatField = ref(false)

const consultationTypes: FormOrderTypeOption[] = [
    { label: Lang.get('common.online'), value: 'online' },
    { label: Lang.get('common.written'), value: 'written' },
    { label: Lang.get('common.in_office'), value: 'office' },
]

const consultationVatOptions: FormOrderVatOption[] = [
    { label: Lang.get('common.no'), value: false },
    { label: Lang.get('common.yes'), value: true },
]

onMounted(() => {
    const savedComment = localStorage.getItem(appConfig.storageKeys.orderComment)

    if (savedComment) {
        form.comment = savedComment
    }
})

function checkUserForExistence(): void {
    if (form.email === '') {
        return
    }

    const requestConf: MakeRequestConfig = {
        method: 'post',
        url: `/api/user/exists/${form.email}`,
    }

    makeRequest<{ exists: boolean }>(requestConf)
        .then(data => {
            if (!data.exists) {
                return
            }

            showThatUserHasAccount(form.email)
        })
}

async function showThatUserHasAccount(email: string): Promise<void> {
    await confirmModal({
        title: Lang.get('common.attention'),
        text: Lang.get('common.you_need_to_sign_in'),
        icon: 'info',
        showCancelButton: false,
    })

    window.location.href = `/login?email=${email}`
}

function saveComment(): void {
    localStorage.setItem(appConfig.storageKeys.orderComment, form.comment)
}
</script>

<template>
    <div class="col-span-1 md:col-span-2 p-2 mt-2">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <div>
                <form-label for="email" :is-required="true">
                    {{ $trans.get('common.your_email') }}
                </form-label>
                <input
                    :class="inputStyles"
                    type="email"
                    id="email"
                    :disabled="!!user"
                    v-model="form.email"
                    @blur="checkUserForExistence"
                    :placeholder="`${$trans.get('common.example')}: johndoe@gmail.com`"
                />
            </div>

            <div>
                <form-label for="phone" :is-required="true">
                    {{ $trans.get('common.phone') }}
                </form-label>
                <input
                    :class="inputStyles"
                    type="text"
                    id="phone"
                    :disabled="!!user"
                    v-model="form.phone"
                    :placeholder="`${$trans.get('common.example')}: +380958811222`"
                />
            </div>

            <div>
                <form-label
                    for="first-name"
                    :is-required="true"
                    :warning="['ru', 'uk'].includes($locale) ? $trans.get('common.latin') : undefined"
                >
                    {{ $trans.get('common.your_first_name') }}
                </form-label>
                <input
                    :class="inputStyles"
                    type="text"
                    id="first-name"
                    v-model="form.first"
                    :disabled="!!user && !!user.first"
                    :placeholder="`${$trans.get('common.example')}: Anna`"
                />
            </div>

            <div>
                <form-label
                    for="last-name"
                    :is-required="true"
                    :warning="['ru', 'uk'].includes($locale) ? $trans.get('common.latin') : undefined"
                >
                    {{ $trans.get('common.your_last_name') }}
                </form-label>
                <input
                    :class="inputStyles"
                    type="text"
                    id="last-name"
                    v-model="form.last"
                    :disabled="!!user && !!user.last"
                    :placeholder="`${$trans.get('common.example')}: Korotchaeva`"
                />
            </div>
        </div>

        <div class="mt-6">
            <form-label for="comment" :is-required="true">
                {{ $trans.get('common.describe_in_details_you_order') }}
            </form-label>

            <textarea
                name="comment"
                id="comment"
                class="border rounded-lg px-4 py-2 focus:outline-main w-full"
                rows="3"
                v-model="form.comment"
                :placeholder="$trans.get('common.describe_in_details_you_order')"
                @blur="saveComment"
            ></textarea>
        </div>
    </div>
</template>
