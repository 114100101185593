<script setup lang="ts">
import type SortItem from '@/interfaces/SortItem'
import type Filter from '@/interfaces/Filter/Filter'
import { ref, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import DropdownTransition from '@/components/Transition/DropdownTransition.vue'
import listenEvent from '@/modules/listenEvent'
import appConfig from '@/appConfig'

interface Emits {
    (e: 'updatedSort', id: number): void
}

const emit = defineEmits<Emits>()

const items: SortItem[] = [
    { id: 1, title: Lang.get('common.popular'), icon: 'lni-cup' },
    { id: 2, title: Lang.get('common.by_price_down'), icon: 'lni-sort-amount-asc' },
    { id: 3, title: Lang.get('common.by_price_up'), icon: 'lni-sort-amount-dsc' },
]

const selected = ref<SortItem>(items[0])

watch(selected, () => {
    emit('updatedSort', selected.value.id)
})

listenEvent<Filter>(appConfig.events.activeFilters, filter => {
    const match = items.find(item => item.id === filter.sort)

    if (match) {
        selected.value = match
    }
})
</script>

<template>
    <div class="flex items-center gap-x-4">
        <i
            v-if="selected"
            class="lni text-2xl text-main"
            :class="selected.icon"
            aria-hidden="true"
        ></i>

        <div class="inline-flex flex-col relative">
            <span class="text-[.7em] uppercase text-font-second">{{ $trans.get('common.sort') }}</span>

            <listbox v-model="selected">
                <listbox-button
                    class="h-full gap-x-3 bg-page flex items-center justify-center relative cursor-pointer sm:text-sm w-[240px] md:w-[260px] border border-border-dark px-5 py-1 rounded-md"
                >
                    <span class="text-sm md:text-md text-font-second block space-x-2 overflow-ellipsis whitespace-nowrap overflow-hidden">
                        {{ selected.title }}
                    </span>

                    <i class="lni lni-chevron-down" aria-hidden="true"></i>
                </listbox-button>

                <dropdown-transition>
                    <listbox-options
                        class="absolute right-0 top-full z-10 mt-1 w-full overflow-hidden rounded-md bg-page text-base shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                        <listbox-option
                            as="template"
                            v-slot="{ active, selected }: { active: boolean, selected: boolean }"
                            v-for="item in items"
                            :key="item.id"
                            :value="item"
                            aria-label="Sort specialists"
                        >
                            <li
                                class="relative select-none py-1 px-3"
                                :class="{
                                    'bg-main text-white': active,
                                    'bg-page text-black': !active,
                                    'bg-main-extra-light text-black': selected,
                                }"
                            >
                                <div class="flex items-center gap-x-2 text-md md:text-sm">
                                    <i
                                        v-if="item.icon"
                                        class="lni"
                                        :class="item.icon"
                                        aria-hidden="true"
                                    ></i>

                                    <span>{{ item.title }}</span>
                                </div>
                            </li>
                        </listbox-option>
                    </listbox-options>
                </dropdown-transition>
            </listbox>
        </div>
    </div>
</template>
