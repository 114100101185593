<script setup lang="ts">
import type OrderType from '@/interfaces/Order/OrderType'
import type CartItem from '@/interfaces/CartItem'
import type BlockItemDate from '@/interfaces/CartItemBlockDate'
import CartSection from './CartSection.vue'
import linkTo from '@/modules/linkTo'
import MeetingCalendar from './MeetingCalendar.vue'
import {onMounted, ref, watch, toRaw} from 'vue'
import {Durations} from '@/interfaces/CartItem'
import OrderCache from '@/modules/Cache/OrderCache'
import CouponCache from '@/modules/Cache/CouponCache'
import ShrinkInsideTransition from '@/components/Transition/ShrinkInsideTransition.vue'
import MakeRequestConfig from "@/interfaces/MakeRequestConfig";
import makeRequest from "@/modules/makeRequest";
import {isNumber} from "lodash";

type Props = {
  cartItem: CartItem
  blockItemDate: BlockItemDate[]
}

type Emits = {
    (e: 'deleteOrder', specialistId: number, serviceId: number): void
}

const emit = defineEmits<Emits>()
const props = defineProps<Props>()
const chooseDate = ref(false)
const durations = ref<Durations>({
  selected: 60,
  options: [
    {text: '60', value: 60}
  ]
})
const types = ref([{text: 'online', value: 'online'}])
const blockItemDate = ref(props.blockItemDate);
const specialistServicePrice = (props.cartItem.service.pivot) ? props.cartItem.service.pivot.price : 0;
const cartItemPrice = (specialistServicePrice) ? ref(specialistServicePrice) : ref(props.cartItem.service.price);
const inputStyles = 'bg-white border px-2 py-1 rounded'
const inputIconStatus = ref<string>();
const couponSize = ref<number>();
const couponPercentage = ref<number>();

onMounted(() => {
  const savedItem = OrderCache.getCartItem(props.cartItem.specialistId, props.cartItem.service.id)

  if (savedItem && savedItem.appointmentDate) {
    chooseDate.value = true
  }

  const savedCoupon = CouponCache.getCouponByServiceId(props.cartItem.specialistId, props.cartItem.service.id)

  if (savedCoupon) {
    props.cartItem.coupon = savedCoupon
  }

  OrderCache.updateDuration(props.cartItem.specialistId, props.cartItem.service.id, durations.value.selected)
  consultationDurationInit();
  consultationTypeInit();
  updateBlockDate();
})

watch(() => durations.value.selected, () => {
  OrderCache.updateDuration(props.cartItem.specialistId, props.cartItem.service.id, durations.value.selected)
  updateCartTotalPriceBySelectedDuration(durations.value.selected);
  updateBlockDate()
})

watch(() => props.cartItem.appointment.date, () => {
  updateBlockDate()
})

watch(() => props.cartItem.coupon, () => {
  couponSize.value = 0;
  couponPercentage.value = 0;

  if (props.cartItem.coupon !== null) {
    if (props.cartItem.coupon.length !== 5) {
      inputIconStatus.value = '';
    }

    if (props.cartItem.coupon?.length === 5) {
      inputIconStatus.value = 'input-icon-coupon-loading';

      const conf: MakeRequestConfig = {
        url: '/api/coupon-check',
        params: {
          code: props.cartItem.coupon,
          service_id: props.cartItem.service.id,
        },
      }

      makeRequest<object>(conf)
          .then((data: object) => {
            if ('coupon_status' in data) {
              if (data['coupon_status'] === true) {
                inputIconStatus.value = 'input-icon-coupon-correct';
                if ('discount_amount' in data && isNumber(data['discount_amount'])) {
                  couponSize.value = data['discount_amount'];
                }
                if ('discount_percentage' in data && isNumber(data['discount_percentage'])) {
                  couponPercentage.value = data['discount_percentage'];
                }
              } else {
                inputIconStatus.value = 'input-icon-coupon-wrong';
              }
            }
          })
          .finally(() => {
            if (inputIconStatus.value === 'input-icon-coupon-loading') {
              inputIconStatus.value = ''
            }
          })
    }
  }
})

function updateCartTotalPriceBySelectedDuration(duration: number) {
  const service = toRaw(props.cartItem.service);
  const service_durations = JSON.parse(service['prices']);

  if (Object.keys(service_durations).length !== 0 && typeof service_durations === 'object') {
    for (const [key, val] of Object.entries(service_durations)) {
      if (Number.parseFloat(key) === duration && typeof val === 'number' && specialistServicePrice < val) {
        cartItemPrice.value = val;
      }
    }
  }
}

function consultationDurationInit(): void {
  let selected = 999999;
  const options: { text: string; value: number }[] = [];
  const service = toRaw(props.cartItem.service);
  const service_durations = JSON.parse(service['prices']);

  if (Object.keys(service_durations).length !== 0 && typeof service_durations === 'object') {
    for (const [key, value] of Object.entries(service_durations)) {
      const parsedKey = Number.parseFloat(key);
      options.push({text: key, value: parsedKey});

      if (parsedKey < selected) {
        selected = parsedKey;
      }
    }
  }

  if (selected !== 999999 && options.length !== 0) {
    durations.value = {
      selected,
      options,
    };

    updateCartTotalPriceBySelectedDuration(selected);
  }
}

function consultationTypeInit() {
    const options: { text: string; value: string }[] = [];
    let service = toRaw(props.cartItem.service);
    let serviceTypes = JSON.parse(service['types']);
    serviceTypes.forEach((type: OrderType) => {
        if (Lang.has('common.' + type)) {
            options.push({
                text: Lang.get('common.' + type), value: type
            })
        } else {
            options.push({
                text: type, value: type
            })
        }
    });

    if (serviceTypes.length && !props.cartItem.type) {
        props.cartItem.type = serviceTypes[0];
    }

    types.value = options;

    OrderCache.updateType(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.type);
}

function updateBlockDate() {
  if (props.cartItem.appointment.date) {
    updateOrAddBlockItemDate(
        props.cartItem.specialistId,
        props.cartItem.service.id,
        durations.value.selected,
        props.cartItem.appointment.date
    )
  }
}

function updateOrAddBlockItemDate(specialistId: number, serviceId: number, duration: number, date: Date) {
  const updatedData: BlockItemDate = {
    specialistId,
    serviceId,
    duration,
    date
  };

  const index = blockItemDate.value.findIndex(item => item.specialistId === specialistId && item.serviceId === serviceId);

  if (index !== -1) {
    blockItemDate.value.splice(index, 1, updatedData);
  } else {
    blockItemDate.value.push(updatedData);
  }
}

function saveAppointment(): void {
  chooseDate.value = true
  OrderCache.updateAppointment(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.appointment.date)
}

function updateCoupon(): void {
  CouponCache.update(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.coupon);
  OrderCache.updateCoupon(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.coupon);
}

function updateType(): void {
  OrderCache.updateType(props.cartItem.specialistId, props.cartItem.service.id, props.cartItem.type);
}
</script>

<template>
  <cart-section>
    <button
        type="button"
        aria-label="Remove all services from order"
        class="absolute top-3 right-3 transition-colors flex items-center justify-center w-9 aspect-square rounded-full hover:bg-page-second"
        @click="emit('deleteOrder', props.cartItem.specialistId, props.cartItem.service.id)"
    >
      <i
          class="lni lni-close text-xs font-bold text-black"
          aria-hidden="true"
      ></i>
    </button>

    <a
        :href="`/specialists/${props.cartItem.specialistSlug}`"
        class="mb-5 items-center flex gap-3"
    >
      <img
          :src="props.cartItem.imageUrl"
          :alt="props.cartItem.title"
          class="rounded-md w-[50px] shadow-md"
          width="50"
          height="50"
      />

      <div>
        <h2 class="text-md md:text-lg text-font-second font-bold">
          {{ $trans.get('common.specialist') }}
        </h2>

        <h2 class="text-xl -mt-1 md:text-2xl text-main-dark">
          {{ props.cartItem.title }}
        </h2>
      </div>
    </a>

    <shrink-inside-transition>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
        <div v-if="props.cartItem.type !== 'written'">
          <h3 class="text-xl mb-3">{{ $trans.get('common.choose_duration') }}</h3>

          <select class="bg-white border px-2 py-1 rounded w-100" id="durations" v-model="durations.selected">
            <option v-for="option in durations.options" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>

        <div>
          <h3 class="text-xl mb-3">{{ $trans.get('common.coupon') }}</h3>

          <div class="input-icon-block w-100">
            <input
                :class="inputStyles"
                class="w-100"
                type="text"
                id="coupon"
                v-model="props.cartItem.coupon"
                @blur="updateCoupon"
            />

            <span
                :class="inputIconStatus"
                class="input-icon"
            ></span>
          </div>
        </div>

        <div v-if="props.cartItem.type !== 'written'">
          <h3 class="text-xl mb-3">{{ $trans.get('common.choose_time') }}</h3>

          <div v-if="cartItem.appointment">
            <meeting-calendar
                v-if="chooseDate"
                :blockItemDate="blockItemDate"
                :cartItem="cartItem"
                :orderType="props.cartItem.type"
                :durations="durations"
            />

            <button
                v-else
                type="button"
                class="w-full max-w-xs bg-gray-500 text-white py-1 px-7 rounded-md shadow-sm relative"
                @click="saveAppointment"
            >
              <div
                  class="absolute -right-1 border border-yellow-500 -top-1 bg-yellow-400 rounded-full w-5 font-black aspect-square animate-ping"></div>
              <i class="lni lni-alarm-clock mr-2"></i>
              <span>{{ $trans.get('common.choose_date') }}</span>
            </button>
          </div>
        </div>

        <div>
          <h3 class="text-xl mb-3">{{ $trans.get('common.consultation_type') }}</h3>

          <select
              v-model="props.cartItem.type"
              @change="updateType"
              class="appearance-none 100 w-100 border px-2 py-1 rounded">
            <option
                v-for="type in types"
                :value="type.value"
            >
              {{ type.text }}
            </option>
          </select>
        </div>
      </div>
    </shrink-inside-transition>

    <h3 class="text-xl my-3">{{ $trans.get('common.chosen_service') }}</h3>

    <ul class="flex flex-col space-y-4 md:space-y-0">
      <li>
        <a
            tabindex="-1"
            aria-label="Go to the service page"
            :href="linkTo(`services/${props.cartItem.service.slug}?specialist=${cartItem.specialistId}`)"
            :title="$trans.get('common.go_to_a_service_page')"
            class="flex items-center gap-3"
        >
          <div class="leading-5">
            <span class="text-font-second">{{ props.cartItem.service.title }}</span>
            <small v-if="cartItemPrice === 0" class="text-main font-bold ml-1">
              {{ $trans.get('common.free') }}
            </small>
            <small v-if="cartItemPrice !== 0" class="text-main font-bold ml-1">
              {{ $trans.get('common.from') }} {{
                cartItemPrice
              }} €
            </small>

          </div>
        </a>

        <div v-if="couponSize && cartItemPrice !== 0" class="leading-5 mt-2">
          <small class="text-font-second">
            {{ $trans.get('common.discount_coupon_of_amount_has_been_applied') }} {{
              couponSize
            }} €
          </small>
        </div>

        <div v-if="couponPercentage && cartItemPrice !== 0" class="leading-5 mt-2">
          <small class="text-font-second">
            {{ $trans.get('common.discount_coupon_of_amount_has_been_applied') }} {{
              couponPercentage
            }} %
          </small>
        </div>
      </li>
    </ul>
  </cart-section>
</template>
