<script setup lang="ts">
import showToast from '@/modules/showToast'
import { onMounted } from 'vue'

type Props = {
    text: string | null
    error?: boolean
}

const { text, error = false } = defineProps<Props>()

onMounted(() => {
    if (text && text !== 'null') {
        showToast({ text, success: !error })
    }
})
</script>

<template>
</template>
