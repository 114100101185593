<script setup lang="ts">
import appConfig from '@/appConfig'
import dispatchEvent from '@/modules/dispatchEvent'
import listenEvent from '@/modules/listenEvent'
import { onMounted, ref } from 'vue'

const menuIsActive = ref(false)

onMounted(() => {
    listenEvent<boolean>(appConfig.events.toggleSidebar, open => {
        menuIsActive.value = open
    })
})

function hamburgerIsClicked(): void {
    const newState = !menuIsActive.value

    menuIsActive.value = newState
    dispatchEvent(appConfig.events.toggleSidebar, newState)
}
</script>

<template>
    <button
        @click="hamburgerIsClicked"
        type="button"
        class="flex min-w-[55px] lg:hidden flex-col gap-y-[6px] py-2 px-4 drop-shadow-font"
        data-toggle="collapse"
        aria-label="Open mobile navigation menu"
    >
        <div
            class="bg-gray-600 h-[2px] w-[23px] rounded-full transition-all duration-500"
            :class="{'-rotate-45 translate-y-[4px] w-[17px]': menuIsActive}"
        ></div>

        <div
            class="bg-gray-600 h-[2px] w-[23px] rounded-full transition-all duration-500"
            :class="{'rotate-45 translate-y-[-4px] w-[17px]': menuIsActive}"
        ></div>
    </button>
</template>
