<script setup lang="ts">
import type SpecialistType from '@/interfaces/Specialist'
import type MakeRequestConfig from '@/interfaces/MakeRequestConfig'
import { onMounted, ref } from 'vue'
import makeRequest from '@/modules/makeRequest'
import SpecialistCard from './SpecialistCard.vue'
import Spinner from '@/components/Spinner.vue'
import Preview from './Preview.vue'
import Filters from '@/components/Filter/Filters.vue'
import NotFound from '@/components/NotFound.vue'

const loading = ref(true)
const specialists = ref<SpecialistType[]>([])
const selectedSpecialist = ref<SpecialistType | null>(null)

onMounted(fetchSpecialists)

function filtersChanged(): void {
    fetchSpecialists()
}

function fetchSpecialists(): void {
    loading.value = true

    specialists.value = []
    selectedSpecialist.value = null

    const config: MakeRequestConfig = {
        url: '/api/specialists' + window.location.search,
    }

    makeRequest<SpecialistType[]>(config)
        .then(data => {
            specialists.value = data
            selectedSpecialist.value = data[0]
        })
        .finally(() => loading.value = false)
}
</script>

<template>
    <div class="pb-7 pt-3 min-h-[500px]">
        <filters
            :title="$trans.get('common.found_specialists')"
            :count="specialists.length"
            :filters="['languages', 'categories', 'services', 'sort', 'query']"
            @filtersChanged="filtersChanged"
        />

        <div class="container">
            <spinner v-if="loading" class="m-auto" />

            <div v-else-if="!loading && specialists.length === 0">
                <not-found>{{ $trans.get('common.specialists_not_found') }}</not-found>
            </div>

            <div v-else class="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div class="space-y-5">
                    <specialist-card
                        v-for="spec in specialists"
                        :key="spec.id"
                        :specialist="spec"
                        @mouseover="selectedSpecialist = spec"
                    />
                </div>
                <div class="hidden lg:block relative">
                    <preview v-if="selectedSpecialist" :specialist="selectedSpecialist" />
                </div>
            </div>
        </div>
    </div>
</template>
